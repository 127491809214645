import { makeRequestActionTypes } from '../../../utils/helpers';

export const getConsultantsTypes = makeRequestActionTypes(
  'users/GET_CONSULTANTS',
);

export const addConsultantTypes = makeRequestActionTypes(
  'users/ADD_CONSULTANT',
);

export const updateConsultantTypes = makeRequestActionTypes(
  'users/UPDATE_CONSULTANT',
);

export const deleteConsultantTypes = makeRequestActionTypes(
  'users/DELETE_CONSULTANT',
);

export const resendConsultantCreationEmailTypes = makeRequestActionTypes(
  'users/RESEND_CONSULTANT_CREATION_EMAIL',
);

export const getUsersTypes = makeRequestActionTypes(
  'users/GET_USERS',
);

export const getUserSelfTypes = makeRequestActionTypes(
  'users/GET_USER_SELF',
);

export const addUserTypes = makeRequestActionTypes(
  'users/ADD_USER',
);

export const updateUserTypes = makeRequestActionTypes(
  'users/UPDATE_USER',
);

export const deleteUserTypes = makeRequestActionTypes(
  'users/DELETE_USER',
);

export const resendUserCreationEmailTypes = makeRequestActionTypes(
  'users/RESEND_USER_CREATION_EMAIL',
);
