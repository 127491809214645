import { map } from 'ramda';
import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

export function useSelectorCreator(selectorCreator, ...args) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selector = useMemo(() => selectorCreator(...args), args);

  return useSelector(selector);
}

export function makeRequestActionTypes(eventName) {
  return ['REQUEST', 'SUCCESS', 'ERROR']
    .reduce((acc, actionTypeVerb) => {
      acc[actionTypeVerb.toLowerCase()] = `${eventName}_${actionTypeVerb}`;

      return acc;
    }, {});
}

export function makeRequestActionCreators(typeMap) {
  return map((actionTypeString) => {
    if (actionTypeString.endsWith('_ERROR')) {
      return (error, meta) => ({
        type: actionTypeString,
        error,
        ...(meta ? { meta } : {}),
      });
    }

    return (payload, meta) => ({
      type: actionTypeString,
      payload,
      ...(meta ? { meta } : {}),
    });
  }, typeMap);
}

export function handleActions(typeMap) {
  return (state, action) => {
    const actionHandler = typeMap[action.type];

    if (!actionHandler) {
      return state;
    }

    return actionHandler(state, action);
  };
}

export function getDefaultRequestState() {
  return {
    loading: false,
    error: false,
  };
}

export function getDefaultLoadingState() {
  return {
    loading: true,
    error: false,
  };
}

export function getDefaultErrorState(error) {
  return {
    loading: false,
    error,
  };
}

export function parseEntityArrayChanges(array, newEntity) {
  if (!newEntity || !newEntity.id) {
    return array;
  }

  const index = array.findIndex((e) => e.id === newEntity.id);

  if (index < 0) {
    return [...array, newEntity];
  }

  const returnArray = [...array];

  returnArray[index] = {
    ...returnArray[index],
    ...newEntity,
  };

  return returnArray;
}

export function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

export const getTextareaHeight = ({
  id,
  defaultHeight = '50px',
}) => {
  // eslint-disable-next-line no-undef
  const element = document.getElementById(id);

  if (!element) {
    return defaultHeight;
  }

  return `${
    (element.scrollHeight + 2)
  }px`;
};
