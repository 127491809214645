/* eslint-disable no-alert */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button, ControlLabel, FormControl, FormGroup, Checkbox, HelpBlock,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUserActions, getUserSelfActions, updateUserActions } from '../redux/actions';
import { selectUserSelf } from '../redux/selectors';
import { LOGOUT_USER } from '../../Auth/redux/actionTypes';
import { selectIsConsultant } from '../../Auth/redux/selectors';

const Profile = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userSelf = useSelector(selectUserSelf);
  const isConsultant = useSelector(selectIsConsultant);

  const [state, setState] = useState({
    secret: '',
    secretRepeat: '',
    descriptionHeight: '50px',
  });

  useEffect(() => {
    dispatch(getUserSelfActions.request());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setState({
      ...state,
      name: userSelf.name || '',
      email: userSelf.email || '',
      phone: userSelf.phone || '',
      counties: userSelf.counties || '',
      description: userSelf.description || '',
      email_notification_flag: !!userSelf.email_notification_flag,
      company_name: userSelf.company_name || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelf]);

  const validateForm = useCallback(() => {
    if (state.secret || state.secretRepeat) {
      return state.secret.length > 7
                && state.secret === state.secretRepeat;
    }
    return true;
  }, [state]);

  const handleChange = useCallback((event) => {
    const newState = {
      [event.target.id]: event.target.value,
    };

    if (event.target.id === 'description') {
      newState.descriptionHeight = `${event.target.scrollHeight + 2}px`;
    }

    setState({
      ...state,
      ...newState,
    });
  }, [state]);

  const handleCheckboxChange = useCallback((event) => {
    setState({
      ...state,
      [event.target.id]: event.target.checked,
    });
  }, [state]);

  const handleDeleteSelf = useCallback(() => {
    if (window.confirm('Kindel et soovite oma profiili kustutada? \n'
        + 'Pärast kustutamist ei ole võimalik süsteemi enam samade tunnustega siseneda.')) {
      dispatch(deleteUserActions.request(userSelf.id));
      dispatch({ type: LOGOUT_USER });
      window.location.reload();
    }
  }, [dispatch, userSelf.id]);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();

    const user = {
      name: state.name,
      email: state.email,
      phone: state.phone,
      counties: state.counties,
      description: state.description,
      email_notification_flag: state.email_notification_flag,
      company_name: state.company_name,
    };

    if (state.secret && state.secretRepeat) {
      user.secret = state.secret;
    }

    user.id = parseInt(userSelf.id, 10);
    dispatch(updateUserActions.request(user));
    history.goBack();
  }, [
    state.name,
    state.email,
    state.phone,
    state.counties,
    state.description,
    state.email_notification_flag,
    state.company_name,
    state.secret,
    state.secretRepeat,
    userSelf.id,
    dispatch,
    history,
  ]);

  return (
    <div style={{
      margin: '0 auto', width: '90%', minWidth: '380px', padding: '20px', borderRadius: '3px', border: '1px solid #ccc',
    }}
    >
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="name">
          <ControlLabel>Kasutaja nimi</ControlLabel>
          <FormControl
            type="text"
            autoComplete="name"
            value={state.name}
            onChange={handleChange}
            disabled={isConsultant}
          />
        </FormGroup>
        <FormGroup controlId="email">
          <ControlLabel>E-post</ControlLabel>
          <FormControl
            value={state.email}
            type="email"
            disabled
          />
        </FormGroup>
        <FormGroup controlId="phone">
          <ControlLabel>Telefon</ControlLabel>
          <FormControl
            value={state.phone}
            onChange={handleChange}
            type="text"
          />
        </FormGroup>
        <FormGroup controlId="company_name">
          <ControlLabel>Asutuse nimi</ControlLabel>
          <FormControl
            autoFocus
            type="text"
            value={state.company_name}
            onChange={handleChange}
            disabled={isConsultant}
          />
        </FormGroup>
        <FormGroup controlId="counties">
          <ControlLabel>Maakonnad</ControlLabel>
          <FormControl
            autoFocus
            type="text"
            value={state.counties}
            onChange={handleChange}
            disabled={isConsultant}
          />
        </FormGroup>
        <FormGroup controlId="description">
          <ControlLabel>Kirjeldus</ControlLabel>
          <FormControl
            autoFocus
            value={state.description}
            onChange={handleChange}
            componentClass="textarea"
            style={{
              minWidth: '100%',
              maxWidth: '100%',
              width: '100%',
              minHeight: '50px',
              height: state.descriptionHeight,
            }}
          />
        </FormGroup>
        <FormGroup
          controlId="secret"
          /* eslint-disable-next-line no-nested-ternary */
          validationState={state.secret ? (state.secret.length > 7 ? 'success' : 'error') : null}
        >
          <ControlLabel>Salasõna</ControlLabel>
          <FormControl
            type="password"
            autoComplete="new-password"
            value={state.secret}
            onChange={handleChange}
          />
          <FormControl.Feedback />
          {state.secret && state.secret.length < 8
                    && <HelpBlock>Minimaalne salasõna pikkus on 8 tähemärki.</HelpBlock>}
        </FormGroup>
        {state.secret && (
          <FormGroup
            controlId="secretRepeat"
            validationState={state.secret === state.secretRepeat ? 'success' : 'error'}
          >
            <ControlLabel>Salasõna uuesti</ControlLabel>
            <FormControl
              type="password"
              autoComplete="new-password"
              value={state.secretRepeat}
              onChange={handleChange}
            />
            <FormControl.Feedback />
            {state.secret !== state.secretRepeat
                    && <HelpBlock>Salasõna ja salasõna kordus ei ole samad!</HelpBlock>}
          </FormGroup>
        )}
        <FormGroup controlId="email_notification_flag">
          <ControlLabel>Soovin saada teavitusi e-postiga</ControlLabel>
          <Checkbox
            id="email_notification_flag"
            checked={state.email_notification_flag}
            onChange={handleCheckboxChange}
          />
          <HelpBlock>
            Valiku deaktiveerimisel ei saadeta sulle ka uute nõustamiste kohta teavitusi!
          </HelpBlock>
        </FormGroup>
        <Button block disabled={!validateForm()} type="submit">
          Salvesta muudatused
        </Button>
      </form>
      <hr />
      <Button type="submit" onClick={handleDeleteSelf}>
        Kustuta oma profiil
      </Button>
    </div>
  );
};

export default Profile;
