import {
  propOr, compose, find, propEq, defaultTo,
} from 'ramda';
import { createSelector } from 'reselect';

const selectUsersState = (state) => state.users;

const selectConsultantsRequestState = compose(
  propOr({}, 'consultantsRequestState'),
  selectUsersState,
);

const selectConsultantsRequestLoading = compose(
  propOr(false, 'loading'),
  selectConsultantsRequestState,
);

const selectConsultantsRequestError = compose(
  propOr(false, 'error'),
  selectConsultantsRequestState,
);

const selectConsultants = compose(
  propOr([], 'consultants'),
  selectUsersState,
);

const selectConsultantRequestState = compose(
  propOr({}, 'consultantRequestState'),
  selectUsersState,
);

const selectConsultantRequestLoading = compose(
  propOr(false, 'loading'),
  selectConsultantRequestState,
);

const selectConsultantRequestError = compose(
  propOr(false, 'error'),
  selectConsultantRequestState,
);

const selectUsersRequestState = compose(
  propOr({}, 'usersRequestState'),
  selectUsersState,
);

const selectUsersRequestLoading = compose(
  propOr(false, 'loading'),
  selectUsersRequestState,
);

const selectUsersRequestError = compose(
  propOr(false, 'error'),
  selectUsersRequestState,
);

const selectUsers = compose(
  propOr([], 'users'),
  selectUsersState,
);

const selectUserRequestState = compose(
  propOr({}, 'userRequestState'),
  selectUsersState,
);

const selectUserRequestLoading = compose(
  propOr(false, 'loading'),
  selectUserRequestState,
);

const selectUserRequestError = compose(
  propOr(false, 'error'),
  selectUserRequestState,
);

const selectUserSelf = compose(
  propOr({}, 'userSelf'),
  selectUsersState,
);

const makeSelectConsultantById = (id) => createSelector(
  selectConsultants,
  compose(
    defaultTo({}),
    find(propEq('id', id)),
  ),
);

const makeSelectUserById = (id) => createSelector(
  selectUsers,
  compose(
    defaultTo({}),
    find(propEq('id', id)),
  ),
);

export {
  selectConsultantsRequestLoading,
  selectConsultantsRequestError,
  selectConsultants,
  selectConsultantRequestLoading,
  selectConsultantRequestError,
  selectUsersRequestLoading,
  selectUsersRequestError,
  selectUsers,
  selectUserRequestLoading,
  selectUserRequestError,
  selectUserSelf,
  makeSelectConsultantById,
  makeSelectUserById,
};
