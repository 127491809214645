import { makeRequestActionCreators } from '../../../utils/helpers';
import {
  getConsultationsTypes,
  getConsultationTypes,
  getPublicConsultationTypes,
  deleteConsultationTypes,
  updateConsultationTypes,
  createConsultationTypes,
  confirmConsultationTypes,
  disproveConsultationTypes,
  rejectConsultationTypes,
  resetConsultationTypes,
  removeConsultantFromConsultationTypes,
  addConsultantToConsultationTypes, RESET_CONSULTATION_ERROR_STATE, RESET_CONSULTATIONS_ERROR_STATE,
} from './actionTypes';

export const getConsultationsActions = makeRequestActionCreators(
  getConsultationsTypes,
);

export const getConsultationActions = makeRequestActionCreators(
  getConsultationTypes,
);

export const getPublicConsultationActions = makeRequestActionCreators(
  getPublicConsultationTypes,
);

export const deleteConsultationActions = makeRequestActionCreators(
  deleteConsultationTypes,
);

export const updateConsultationActions = makeRequestActionCreators(
  updateConsultationTypes,
);

export const createConsultationActions = makeRequestActionCreators(
  createConsultationTypes,
);

export const confirmConsultationActions = makeRequestActionCreators(
  confirmConsultationTypes,
);

export const disproveConsultationActions = makeRequestActionCreators(
  disproveConsultationTypes,
);

export const rejectConsultationActions = makeRequestActionCreators(
  rejectConsultationTypes,
);

export const resetConsultationActions = makeRequestActionCreators(
  resetConsultationTypes,
);

export const removeConsultantFromConsultationActions = makeRequestActionCreators(
  removeConsultantFromConsultationTypes,
);

export const addConsultantToConsultationActions = makeRequestActionCreators(
  addConsultantToConsultationTypes,
);

export const resetConsultationErrorState = () => ({
  type: RESET_CONSULTATION_ERROR_STATE,
});

export const resetConsultationsErrorState = () => ({
  type: RESET_CONSULTATIONS_ERROR_STATE,
});
