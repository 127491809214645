import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  ButtonGroup,
  Form,
  HelpBlock,
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {
  createConsultationActions,
} from '../redux/actions';
import {
  emailRegex,
  cadastralIdRegex,
} from '../../../utils/constants';

const NewConsultation = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [consultation, setConsultation] = useState({
    cadastral_id: '',
    client_email: '',
    client_phone: '',
    description: '',
    location_description: '',
    status: 'applied',
  });
  const [descriptionHeight, setDescriptionHeight] = useState('50px');

  const handleSubmit = useCallback((event) => {
    event.preventDefault();

    dispatch(createConsultationActions.request(consultation));

    return history.push('/consultations');
  }, [consultation, dispatch, history]);

  const handleChange = useCallback((event) => {
    const { id, value, scrollHeight } = event.target;

    if (id === 'description') {
      setDescriptionHeight(`${scrollHeight + 2}px`);
    }

    setConsultation({
      ...consultation,
      [id]: value,
    });
  }, [consultation]);

  const validateForm = useCallback(() => emailRegex.test(consultation.client_email)
        && cadastralIdRegex.test(consultation.cadastral_id)
        && !!consultation.description,
  [consultation]);

  return (
    <div>
      <div style={{
        margin: '0 auto', width: '90%', minWidth: '380px', padding: '20px', borderRadius: '3px', border: '1px solid #ccc',
      }}
      >
        <form onSubmit={handleSubmit}>
          <Form componentClass="div" inline>
            <FormGroup
              controlId="cadastral_id"
              validationState={(cadastralIdRegex.test(consultation.cadastral_id) ? 'success' : 'error')}
            >
              <ControlLabel style={{ margin: '2px' }}>Katastritunnus: </ControlLabel>
              <FormControl
                autoFocus
                type="text"
                value={consultation.cadastral_id}
                onChange={handleChange}
                style={{ margin: '2px', marginRight: '6px' }}
              />
              <FormControl.Feedback />
              {!cadastralIdRegex.test(consultation.cadastral_id)
                            && (
                            <HelpBlock>
                              Palun kontrolli katastritunnus
                              <a href="https://xgis.maaamet.ee/xgis2/page/app/maainfo"> maaametist</a>
                              !
                            </HelpBlock>
                            )}
            </FormGroup>
          </Form>
          <br />
          <FormGroup
            controlId="description"
            validationState={consultation.description ? 'success' : 'error'}
          >
            <ControlLabel>Kirjeldus: </ControlLabel>
            <FormControl
              value={consultation.description || ''}
              onChange={handleChange}
              componentClass="textarea"
              style={{
                minWidth: '100%',
                maxWidth: '100%',
                width: '100%',
                minHeight: '50px',
                height: descriptionHeight,
              }}
            />
          </FormGroup>
          <br />
          <Form componentClass="div" inline>
            <FormGroup
              controlId="client_email"
              validationState={emailRegex.test(consultation.client_email) ? 'success' : 'error'}
            >
              <ControlLabel style={{ margin: '2px' }}>Kliendi e-post: </ControlLabel>
              <FormControl
                value={consultation.client_email}
                onChange={handleChange}
                type="email"
                style={{ margin: '2px', marginRight: '6px' }}
              />
              <FormControl.Feedback />
            </FormGroup>
            <FormGroup controlId="client_phone">
              <ControlLabel style={{ margin: '2px' }}>Kliendi telefon: </ControlLabel>
              <FormControl
                value={consultation.client_phone}
                onChange={handleChange}
                type="text"
                style={{ margin: '2px', marginRight: '6px' }}
              />
            </FormGroup>
          </Form>
          <hr />
          <ButtonGroup style={{ width: '100%' }}>
            <Button
              disabled={!validateForm()}
              type="submit"
              style={{ width: '100%' }}
            >
              Loo uus nõustamine
            </Button>
          </ButtonGroup>
        </form>
      </div>
    </div>
  );
};

export default NewConsultation;
