import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  bool, func, object, oneOfType,
} from 'prop-types';
import {
  Alert,
  Button, ControlLabel, FormControl, FormGroup, Modal,
} from 'react-bootstrap';
import { createStructuredSelector } from 'reselect';
import { emailRegex } from '../../../utils/constants';
import { hideRefreshSessionModal, refreshSessionActions, resetRefreshSessionState } from '../redux/actions';
import {
  selectRefreshSessionError,
  selectRefreshSessionLoading,
  selectShowRefreshSessionModal,
} from '../redux/selectors';
import ErrorBar from '../../../components/ErrorBar/ErrorBar';

const RefreshSessionToken = ({
  show,
  isRefreshSessionLoading,
  refreshSessionError,
  refreshSession,
  resetState,
  hide,
}) => {
  const [email, setEmail] = useState('');
  const [secret, setSecret] = useState('');
  const [isSignInFormValid, setIsSignInFormValid] = useState(true);

  const handleLoginFormSubmit = useCallback((event) => {
    event.preventDefault();
    refreshSession({ email, secret });
  }, [refreshSession, email, secret]);

  useEffect(() => {
    setIsSignInFormValid(emailRegex.test(email) && secret.length > 7);
  }, [email, secret]);

  return (
    <React.Fragment>
      <Modal show={show} onHide={hide}>
        <Modal.Header closeButton>
          <Modal.Title>
            Teie sessioon on aegunud. Jätkamiseks palun logige uuesti sisse.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isRefreshSessionLoading && (
            <Alert bsStyle="info">
              <strong>Logime teid sisse, palun oodake</strong>
            </Alert>
          )}
          <ErrorBar
            error={refreshSessionError}
            resetErrorState={resetState}
          />
          <form onSubmit={handleLoginFormSubmit}>
            <FormGroup controlId="email" bsSize="large">
              <ControlLabel>E-posti aadress</ControlLabel>
              <FormControl
                autoFocus
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                autoComplete="username"
              />
            </FormGroup>
            <FormGroup controlId="secret" bsSize="large">
              <ControlLabel>Parool</ControlLabel>
              <FormControl
                value={secret}
                onChange={(event) => setSecret(event.target.value)}
                type="password"
                autoComplete="current-password"
              />
            </FormGroup>
            <Button
              block
              bsSize="large"
              disabled={!isSignInFormValid}
              type="submit"
            >
              Logi sisse!
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

RefreshSessionToken.propTypes = {
  show: bool.isRequired,
  isRefreshSessionLoading: bool.isRequired,
  refreshSessionError: oneOfType([bool, object]).isRequired,
  refreshSession: func.isRequired,
  resetState: func.isRequired,
  hide: func.isRequired,
};

export default connect(createStructuredSelector({
  show: selectShowRefreshSessionModal,
  isRefreshSessionLoading: selectRefreshSessionLoading,
  refreshSessionError: selectRefreshSessionError,
}), {
  refreshSession: refreshSessionActions.request,
  resetState: resetRefreshSessionState,
  hide: hideRefreshSessionModal,
})(RefreshSessionToken);
