import { all } from 'redux-saga/effects';
import authSaga from './modules/Auth/redux/sagas';
import consultationsSaga from './modules/Consultations/redux/sagas';
import usersSaga from './modules/Users/redux/sagas';

export default function* rootSaga() {
  yield all([
    ...authSaga,
    ...consultationsSaga,
    ...usersSaga,
  ]);
}
