import React from 'react';
import { Glyphicon, Badge, Table } from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import { formatConsultationStatus } from './format';
import UsersControlButtons from '../modules/Consultations/components/ActionButtons/UsersControlButtons';
import ConsultationControlButtons from '../modules/Consultations/components/ActionButtons/ConsultationControlButtons';
import ConsultationConsultantControlButton from '../modules/Consultations/components/ActionButtons/ConsultationConsultantControlButton';
import ToolTip from './ToolTip';
import { colors } from './constants';

export const EmailFormatter = (email) => <a href={`mailto:${email}`}>{email}</a>;

export const ConsultationStatusFormatter = (status, { add_time: addTime }) => {
  const daysSinceCreated = moment(moment()).diff(addTime, 'days');
  const statusElement = formatConsultationStatus(status);

  return (
    <div>
      {ToolTip(
        'days-since-created',
        <Badge pullRight>{daysSinceCreated}</Badge>,
      )}
      <p>
        <Glyphicon style={{ fontSize: '2em', color: statusElement.color }} glyph={statusElement.icon} />
        <span style={{ marginLeft: '10px' }}>{statusElement.text}</span>
      </p>
    </div>
  );
};

export const CadastralIdFormatter = (cadastralId, { repeated_visit_flag: flag }) => (
  <p style={flag ? { color: colors.Red } : {}}>
    {cadastralId}
  </p>
);

export const ExpandedRowInformationFormatter = ({ row }) => (
  <Table bordered condensed responsive>
    <tbody>
      <tr>
        <td>
          Maakond:
          {row.county}
        </td>
        <td rowSpan={4} colSpan={2}>
          <div>
            <b>Kirjeldus:</b>
            <br />
            {row.description}
          </div>
        </td>
        <td>
          Konsulteerija:
          {row.consultant_name}
        </td>
      </tr>
      <tr>
        <td>
          Omavalitsus:
          {row.institution}
        </td>
        <td>
          Firma:
          {row.consultant_company_name}
        </td>
      </tr>
      <tr>
        <td>
          Asustusüksus:
          {row.municipality}
        </td>
        <td>
          E-post:
          { new EmailFormatter(row.consultant_email)}
        </td>
      </tr>
      <tr>
        <td>
          Asukoht:
          {row.local_address}
        </td>
        <td>
          Telefon:
          {row.consultant_phone}
        </td>
      </tr>
    </tbody>
  </Table>
);

ExpandedRowInformationFormatter.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.any.isRequired,
};

export const DisabledCheckboxFormatter = (value) => (
  <input
    type="checkbox"
    disabled
    defaultChecked={!!value}
  />
);

export const IsAdministratorFormatter = (accountType) => (
  <input
    type="checkbox"
    disabled
    defaultChecked={accountType === 'admin'}
  />
);

export const CheckboxFormatter = (value) => (
  <input
    type="checkbox"
    checked={!!value}
  />
);

export const UserActionButtonFormatter = (
  userId,
  { account_type: accountType, email_verified_flag: isEmailVerified },
  rowIndex,
  formatExtraData,
) => {
  const { deleteFunction, resendAccountCreationEmailFunction } = formatExtraData;

  return (
    <UsersControlButtons
      deleteFunction={deleteFunction}
      resendAccountCreationEmailFunction={resendAccountCreationEmailFunction}
      userId={userId}
      userIsAdmin={accountType === 'admin'}
      emailIsConfirmed={!!isEmailVerified}
    />
  );
};

export const ActionButtonFormatter = (consultationId, row) => (
  <ConsultationControlButtons
    consultation={row}
  />
);

export const DescriptionFormatter = (description) => {
  if (!description || !description.length || description.length < 50) {
    return description;
  }

  return `${description.substring(0, 50)}...`;
};

export const ConsultantNameFieldFormatter = (consultationId, row, rowIndex, formatExtraData) => {
  const {
    handleShowConsultantSelectionModal,
  } = formatExtraData;

  return (
    <ConsultationConsultantControlButton
      consultation={row}
      handleShowConsultantSelectionModal={handleShowConsultantSelectionModal}
    />
  );
};
