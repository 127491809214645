import { Type } from 'react-bootstrap-table2-editor';
import {
  EmailFormatter,
  DisabledCheckboxFormatter,
  IsAdministratorFormatter,
  UserActionButtonFormatter,
} from './TableItemFormatters';

export const userTableExtraHeaderData = [{
  dataField: 'account_type',
  text: 'Administraator',
  formatter: IsAdministratorFormatter,
  editable: false,
}];

function actionColumn(deleteFunction, resendAccountCreationEmailFunction) {
  return [{
    dataField: 'id',
    text: '',
    formatter: UserActionButtonFormatter,
    formatExtraData: {
      deleteFunction,
      resendAccountCreationEmailFunction,
    },
    searchable: false,
  }];
}

export function getTableHeaderData(
  isConsultantView,
  deleteFunction,
  resendAccountCreationEmailFunction,
) {
  let tableHeaders = [{
    dataField: 'name',
    text: 'Nimi',
  }, {
    dataField: 'email',
    text: 'E-post',
    formatter: EmailFormatter,
    editable: false,
  }, {
    dataField: 'phone',
    text: 'Telefon',
  }, {
    dataField: 'company_name',
    text: 'Asutus',
  }, {
    dataField: 'description',
    text: 'Kirjeldus',
    editor: {
      type: Type.TEXTAREA,
    },
  }, {
    dataField: 'counties',
    text: 'Maakonnad',
    editor: {
      type: Type.TEXTAREA,
    },
  }, {
    dataField: 'email_verified_flag',
    text: 'Email kinnitatud',
    formatter: DisabledCheckboxFormatter,
    editable: false,
  }, {
    dataField: 'email_notification_flag',
    text: 'Teavitused e-postiga',
    formatter: DisabledCheckboxFormatter,
    editable: false,
  }];

  if (!isConsultantView) {
    tableHeaders = tableHeaders.concat(userTableExtraHeaderData);
  }

  tableHeaders = tableHeaders.concat(
    actionColumn(deleteFunction, resendAccountCreationEmailFunction),
  );

  return tableHeaders;
}
