import { makeRequestActionCreators } from '../../../utils/helpers';
import {
  getConsultantsTypes,
  addConsultantTypes,
  updateConsultantTypes,
  deleteConsultantTypes,
  resendConsultantCreationEmailTypes,
  getUsersTypes,
  getUserSelfTypes,
  addUserTypes,
  updateUserTypes,
  deleteUserTypes,
  resendUserCreationEmailTypes,
} from './actionTypes';

export const getConsultantsActions = makeRequestActionCreators(
  getConsultantsTypes,
);

export const addConsultantActions = makeRequestActionCreators(
  addConsultantTypes,
);

export const updateConsultantActions = makeRequestActionCreators(
  updateConsultantTypes,
);

export const deleteConsultantActions = makeRequestActionCreators(
  deleteConsultantTypes,
);

export const resendConsultantCreationEmailActions = makeRequestActionCreators(
  resendConsultantCreationEmailTypes,
);

export const getUsersActions = makeRequestActionCreators(
  getUsersTypes,
);

export const getUserSelfActions = makeRequestActionCreators(
  getUserSelfTypes,
);

export const addUserActions = makeRequestActionCreators(
  addUserTypes,
);

export const updateUserActions = makeRequestActionCreators(
  updateUserTypes,
);

export const deleteUserActions = makeRequestActionCreators(
  deleteUserTypes,
);

export const resendUserCreationEmailActions = makeRequestActionCreators(
  resendUserCreationEmailTypes,
);
