import { makeRequestActionTypes } from '../../../utils/helpers';

export const LOGOUT_USER = 'auth/LOGOUT_USER';
export const RESET_AUTH_STATE = 'auth/RESET_AUTH_STATE';
export const RESTORE_SESSION_SUCCESS = 'auth/RESTORE_SESSION_SUCCESS';
export const RESET_REFRESH_SESSION_STATE = 'auth/RESET_REFRESH_SESSION_STATE';
export const SHOW_REFRESH_SESSION_MODAL = 'auth/SHOW_REFRESH_SESSION_MODAL';
export const HIDE_REFRESH_SESSION_MODAL = 'auth/HIDE_REFRESH_SESSION_MODAL';

export const loginUserTypes = makeRequestActionTypes(
  'auth/LOGIN_USER',
);
export const refreshSessionTypes = makeRequestActionTypes(
  'auth/REFRESH_SESSION',
);
export const sendEmailReminderTypes = makeRequestActionTypes(
  'auth/SEND_EMAIL_REMINDER',
);
