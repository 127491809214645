import { Glyphicon } from 'react-bootstrap';
import React from 'react';
import { multiSelectFilter } from 'react-bootstrap-table2-filter';
import { cleanLineEndindgs, formatConsultationStatus } from '../../../utils/format';
import {
  ActionButtonFormatter,
  CadastralIdFormatter,
  ConsultantNameFieldFormatter,
  ConsultationStatusFormatter,
  DescriptionFormatter,
  EmailFormatter,
  ExpandedRowInformationFormatter,
} from '../../../utils/TableItemFormatters';
import { ALL_STATUSES, filterStatusDefinitions } from '../../../utils/constants';

export const getColumns = ({
  handleShowConsultantSelectionModal,
  setStatusFilter,
}) => [{
  dataField: 'status',
  text: 'Staatus',
  formatter: ConsultationStatusFormatter,
  csvFormatter: (status) => formatConsultationStatus(status).tooltip,
  sort: true,
  filter: multiSelectFilter({
    options: filterStatusDefinitions,
    defaultValue: ALL_STATUSES,
    withoutEmptyOption: false,
    getFilter: (f) => {
      setStatusFilter(f);
    },
    style: {
      display: 'none',
    },
  }),
},
{
  dataField: 'cadastral_id',
  text: 'Katastritunnus',
  formatter: CadastralIdFormatter,
  sort: true,
},
{
  dataField: 'county',
  text: 'Maakond',
  sort: true,
},
{
  dataField: 'local_address',
  text: 'Asukoht',
  hidden: true,
},
{
  dataField: 'institution',
  text: 'Omavalitsus',
  hidden: true,
},
{
  dataField: 'municipality',
  text: 'Asustusyksus',
  hidden: true,
},
{
  dataField: 'location_description',
  text: 'Asukoha kirjeldus',
  csvFormatter: (description) => cleanLineEndindgs(description),
  hidden: true,
},
{
  dataField: 'description',
  text: 'Kirjeldus',
  csvFormatter: (description) => cleanLineEndindgs(description),
  formatter: DescriptionFormatter,
},
{
  dataField: 'client_email',
  text: 'Kliendi e-post',
  formatter: EmailFormatter,
  sort: true,
},
{
  dataField: 'client_phone',
  text: 'Kliendi telefon',
  csvFormatter: (phone) => cleanLineEndindgs(phone),
  sort: true,
},
{
  dataField: 'consultant_id',
  text: 'Konsulteerija andmebaasi viit',
  csvType: Number,
  hidden: true,
  searchable: false,
},
{
  dataField: 'consultant_name',
  text: 'Konsulteerija',
  formatter: ConsultantNameFieldFormatter,
  formatExtraData: {
    handleShowConsultantSelectionModal,
  },
  sort: true,
},
{
  dataField: 'consultant_phone',
  text: 'Konsultandi telefon',
  csvFormatter: (description) => cleanLineEndindgs(description),
  hidden: true,
},
{
  dataField: 'consultant_email',
  text: 'Konsultandi e-post',
  hidden: true,
},
{
  dataField: 'consultant_company_name',
  csvFormatter: (description) => cleanLineEndindgs(description),
  text: 'Konsultandi firma nimi',
  hidden: true,
},
{
  dataField: 'id',
  text: 'Tegevused',
  formatter: ActionButtonFormatter,
  searchable: false,
  csvType: Number,
},
{
  dataField: 'work_start_time',
  text: 'Töö alguse aeg',
  hidden: true,
  searchable: false,
},
{
  dataField: 'work_end_time',
  text: 'Töö lõpu aeg',
  hidden: true,
  searchable: false,
},
{
  dataField: 'repeated_visit_flag',
  text: 'Korduv nõustamine?',
  csvType: Number,
  hidden: true,
  searchable: false,
},
{
  dataField: 'created_by',
  text: 'Looja andmebaasi viit',
  csvType: Number,
  hidden: true,
  searchable: false,
},
{
  dataField: 'updated_by',
  text: 'Viimati uuendaja andmebaasi viit',
  csvType: Number,
  hidden: true,
  searchable: false,
},
{
  dataField: 'archived_flag',
  text: 'Arhiveeritud?',
  csvType: Number,
  hidden: true,
  searchable: false,
},
{
  dataField: 'add_time',
  text: 'Lisamise aeg',
  hidden: true,
  searchable: false,
},
{
  dataField: 'update_time',
  text: 'Uuendamise aeg',
  hidden: true,
  searchable: false,
}];

export const expandRow = {
  // eslint-disable-next-line react/display-name
  renderer: (row) => (
    <ExpandedRowInformationFormatter row={row} />
  ),
  showExpandColumn: true,
  expandByColumnOnly: true,
  // eslint-disable-next-line react/display-name
  expandHeaderColumnRenderer: ({ isAnyExpands }) => {
    if (isAnyExpands) {
      return <Glyphicon glyph="glyphicon glyphicon-chevron-up" />;
    }

    return <Glyphicon glyph="glyphicon glyphicon-chevron-down" />;
  },
  // eslint-disable-next-line react/display-name
  expandColumnRenderer: ({ expanded }) => {
    if (expanded) {
      return (
        <Glyphicon glyph="glyphicon glyphicon-chevron-up" />
      );
    }

    return (
      <Glyphicon glyph="glyphicon glyphicon-chevron-down" />
    );
  },
};

export const customTotal = (from, to, size) => {
  if (from === 1 && to === size && size < 25) {
    return null;
  }

  return (
    <span className="react-bootstrap-table-pagination-total">
      Kuvatakse tulemused
      { from }
      {' '}
      kuni
      { to }
      {' '}
      /
      { size }
    </span>
  );
};
