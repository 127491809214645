/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import {
  getDefaultErrorState,
  getDefaultLoadingState,
  getDefaultRequestState,
  handleActions,
} from '../../../utils/helpers';
import {
  loginUserTypes,
  refreshSessionTypes,
  RESET_REFRESH_SESSION_STATE,
  RESET_AUTH_STATE,
  RESTORE_SESSION_SUCCESS,
  sendEmailReminderTypes,
  SHOW_REFRESH_SESSION_MODAL,
  HIDE_REFRESH_SESSION_MODAL,
} from './actionTypes';

const makeInitialState = () => ({
  authRequest: getDefaultRequestState(),
  refreshSessionRequest: getDefaultRequestState(),
  showRefreshSessionModal: false,
  session: {
    token: false,
  },
});

const handleAuthRequest = produce((draft) => {
  draft.authRequest = getDefaultLoadingState();
  draft.session = {};
});

const handleAuthError = produce((draft, { error }) => {
  draft.authRequest = getDefaultErrorState(error);
  draft.session = {};
});

const handleLoginUserSuccess = produce((draft, { payload: session }) => {
  draft.authRequest = getDefaultRequestState();
  draft.session = session;
});

const handleRefreshSessionRequest = produce((draft) => {
  draft.refreshSessionRequest = getDefaultLoadingState();
});

const handleRefreshSessionError = produce((draft, { error }) => {
  draft.refreshSessionRequest = getDefaultErrorState(error);
});

const handleRefreshSessionSuccess = produce((draft, { payload: session }) => {
  draft.refreshSessionRequest = getDefaultRequestState();
  draft.showRefreshSessionModal = false;
  draft.session = session;
});

const handleResetRefreshSessionState = produce((draft) => {
  draft.refreshSessionRequest = getDefaultRequestState();
});

const handleShowRefreshSessionModal = produce((draft) => {
  draft.showRefreshSessionModal = true;
});

const handleHideRefreshSessionModal = produce((draft) => {
  draft.showRefreshSessionModal = false;
});

const handleResetAuthState = produce((draft) => {
  draft.authRequest = getDefaultRequestState();
  draft.session = {};
  draft.showRefreshSessionModal = false;
});

const typeMap = {
  [loginUserTypes.request]: handleAuthRequest,
  [loginUserTypes.error]: handleAuthError,
  [loginUserTypes.success]: handleLoginUserSuccess,

  [refreshSessionTypes.request]: handleRefreshSessionRequest,
  [refreshSessionTypes.error]: handleRefreshSessionError,
  [refreshSessionTypes.success]: handleRefreshSessionSuccess,
  [RESET_REFRESH_SESSION_STATE]: handleResetRefreshSessionState,
  [SHOW_REFRESH_SESSION_MODAL]: handleShowRefreshSessionModal,
  [HIDE_REFRESH_SESSION_MODAL]: handleHideRefreshSessionModal,

  [RESTORE_SESSION_SUCCESS]: handleLoginUserSuccess,

  [sendEmailReminderTypes.request]: handleAuthRequest,
  [sendEmailReminderTypes.error]: handleAuthError,
  [sendEmailReminderTypes.success]: handleResetAuthState,

  [RESET_AUTH_STATE]: handleResetAuthState,
};

export default function authReducer(state = makeInitialState(), action) {
  return handleActions(typeMap)(state, action);
}
