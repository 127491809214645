import { call, put, takeLatest } from 'redux-saga/effects';
import { api, sessionApi } from '../../../utils/api/sagaApi';
import {
  getConsultationsActions,
  getConsultationActions,
  getPublicConsultationActions,
  deleteConsultationActions,
  updateConsultationActions,
  createConsultationActions,
  confirmConsultationActions,
  disproveConsultationActions,
  rejectConsultationActions,
  resetConsultationActions,
  removeConsultantFromConsultationActions,
  addConsultantToConsultationActions,
} from './actions';
import {
  getConsultationsTypes,
  getConsultationTypes,
  getPublicConsultationTypes,
  deleteConsultationTypes,
  updateConsultationTypes,
  createConsultationTypes,
  confirmConsultationTypes,
  disproveConsultationTypes,
  rejectConsultationTypes,
  resetConsultationTypes,
  removeConsultantFromConsultationTypes,
  addConsultantToConsultationTypes,
} from './actionTypes';

function* getConsultations() {
  try {
    const data = yield call(sessionApi, {
      path: '/client/consultations?include_consultants=true',
    });

    yield put(getConsultationsActions.success(data));
  } catch (error) {
    yield put(getConsultationsActions.error(error));
  }
}

function* getConsultation({ payload: consultationId }) {
  try {
    const data = yield call(sessionApi, {
      path: `/client/consultations/${consultationId}`,
    });

    yield put(getConsultationActions.success(data));
  } catch (error) {
    yield put(getConsultationActions.error(error));
  }
}

function* getPublicConsultation({ payload: consultationUuid }) {
  try {
    const data = yield call(api, {
      path: `/public/consultation/${consultationUuid}`,
    });

    yield put(getPublicConsultationActions.success(data));
  } catch (error) {
    yield put(getPublicConsultationActions.error(error));
  }
}

function* deleteConsultation({ payload: consultationId }) {
  try {
    yield call(sessionApi, {
      path: `/client/consultations/${consultationId}`,
      method: 'DELETE',
    });

    yield put(deleteConsultationActions.success(consultationId));
  } catch (error) {
    yield put(deleteConsultationActions.error(error));
  }
}

function* updateConsultation({ payload: consultation }) {
  try {
    const data = yield call(sessionApi, {
      path: `/client/consultations/${consultation.id}`,
      method: 'PUT',
      body: {
        client_email: consultation.client_email,
        client_phone: consultation.client_phone,
        description: consultation.description,
        location_description: consultation.location_description,
        buildings: consultation.buildings,
        ...(consultation.status ? { status: consultation.status } : {}),
      },
    });

    yield put(updateConsultationActions.success(data));
  } catch (error) {
    yield put(updateConsultationActions.error(error));
  }
}

function* createConsultation({ payload: consultation }) {
  try {
    const data = yield call(sessionApi, {
      path: '/client/consultations',
      method: 'POST',
      body: {
        cadastral_id: consultation.cadastral_id,
        client_email: consultation.client_email,
        client_phone: consultation.client_phone,
        description: consultation.description,
        location_description: consultation.location_description,
        status: consultation.status || 'applied',
      },
    });

    yield put(createConsultationActions.success(data));
  } catch (error) {
    yield put(createConsultationActions.error(error));
  }
}

function* confirmConsultation({ payload: { consultationId, skipEmails } }) {
  try {
    const query = skipEmails ? '?skip_emails=true' : '';
    const data = yield call(sessionApi, {
      path: `/client/consultations/${consultationId}/confirm${query}`,
      method: 'PUT',
    });

    yield put(confirmConsultationActions.success(data));
  } catch (error) {
    yield put(confirmConsultationActions.error(error));
  }
}

function* disproveConsultation({ payload: consultationId }) {
  try {
    const data = yield call(sessionApi, {
      path: `/client/consultations/${consultationId}/disprove`,
      method: 'PUT',
    });

    yield put(disproveConsultationActions.success(data));
  } catch (error) {
    yield put(disproveConsultationActions.error(error));
  }
}

function* resetConsultation({ payload: consultationId }) {
  try {
    const data = yield call(sessionApi, {
      path: `/client/consultations/${consultationId}`,
      method: 'PUT',
      body: {
        status: 'applied',
        consultant_id: null,
        work_start_time: null,
        work_end_time: null,
        archived_flag: 0,
      },
    });

    yield put(resetConsultationActions.success(data));
  } catch (error) {
    yield put(resetConsultationActions.error(error));
  }
}

function* rejectConsultation({ payload: consultationId }) {
  try {
    const data = yield call(sessionApi, {
      path: `/client/consultations/${consultationId}/reject`,
      method: 'PUT',
    });

    yield put(rejectConsultationActions.success(data));
  } catch (error) {
    yield put(rejectConsultationActions.error(error));
  }
}

function* removeConsultantFromConsultation({ payload: consultationId }) {
  try {
    const data = yield call(sessionApi, {
      path: `/client/consultations/${consultationId}/decline`,
      method: 'PUT',
    });

    yield put(removeConsultantFromConsultationActions.success(data));
  } catch (error) {
    yield put(removeConsultantFromConsultationActions.error(error));
  }
}

function* addConsultantToConsultation({
  payload: { consultationId, consultantId, sendConfirmation },
}) {
  try {
    const data = yield call(sessionApi, {
      path: `/client/consultations/${consultationId}/consultant/${consultantId}${
        sendConfirmation ? '?send_confirmation=true' : ''
      }`,
      method: 'POST',
    });

    yield put(addConsultantToConsultationActions.success(data));
  } catch (error) {
    yield put(addConsultantToConsultationActions.error(error));
  }
}

export default [
  takeLatest(getConsultationsTypes.request, getConsultations),
  takeLatest(getConsultationTypes.request, getConsultation),
  takeLatest(getPublicConsultationTypes.request, getPublicConsultation),
  takeLatest(deleteConsultationTypes.request, deleteConsultation),
  takeLatest(updateConsultationTypes.request, updateConsultation),
  takeLatest(createConsultationTypes.request, createConsultation),
  takeLatest(confirmConsultationTypes.request, confirmConsultation),
  takeLatest(disproveConsultationTypes.request, disproveConsultation),
  takeLatest(rejectConsultationTypes.request, rejectConsultation),
  takeLatest(resetConsultationTypes.request, resetConsultation),
  takeLatest(removeConsultantFromConsultationTypes.request, removeConsultantFromConsultation),
  takeLatest(addConsultantToConsultationTypes.request, addConsultantToConsultation),
];
