import React from 'react';
import {
  Glyphicon, Nav, Navbar, NavItem,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { bool } from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectIsAdmin, selectIsConsultant } from '../../modules/Auth/redux/selectors';
import RefreshSessionToken from '../../modules/Auth/components/RefreshSessionModal';

const Header = ({ isConsultant, isAdmin }) => (
  <div>
    <Navbar
      collapseOnSelect
      inverse
    >
      <Navbar.Header>
        <LinkContainer to="/consultations">
          <NavItem>
            <Navbar.Brand>
              Nõustamised
            </Navbar.Brand>
          </NavItem>
        </LinkContainer>
        <Navbar.Toggle />
      </Navbar.Header>
      <Navbar.Collapse>
        <Nav pullRight>
          {isAdmin && <LinkContainer to="/users"><NavItem>Kasutajad</NavItem></LinkContainer>}
          {!isConsultant && <LinkContainer to="/consultants"><NavItem>Konsultandid</NavItem></LinkContainer>}
          <LinkContainer to="/profile"><NavItem>Profiil</NavItem></LinkContainer>
          <LinkContainer to="/logout"><NavItem>Logi välja</NavItem></LinkContainer>
          <LinkContainer to="/help">
            <NavItem>
              <Glyphicon style={{ fontSize: '1.3em' }} glyph="glyphicon glyphicon-question-sign" />
            </NavItem>
          </LinkContainer>
          <RefreshSessionToken />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </div>
);

Header.propTypes = {
  isAdmin: bool.isRequired,
  isConsultant: bool.isRequired,
};

export default connect(createStructuredSelector({
  isAdmin: selectIsAdmin,
  isConsultant: selectIsConsultant,
}))(Header);
