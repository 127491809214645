// eslint-disable-next-line max-len
export const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
export const cadastralIdRegex = /^\d{5}:\d{3}:\d{4}$/;

export const SESSION_TOKEN_HEADER_NAME = 'X-Mkask-Session-Token';

export const colors = {
  Red: '#ff0000',
  Orange: '#ffa500',
  Deepskyblue: '#00bfff',
  Chartreuse: '#7fff00',
  Green: '#008000',
};

export const safeParseInt = (input, defaultValue = 'new') => {
  try {
    const id = parseInt(input, 10);

    if (!Number.isNaN(id)) {
      return id;
    }

    return defaultValue;
  } catch (err) {
    return defaultValue;
  }
};

export const statusMap = [
  { id: 1, value: ['applied'], name: 'Soovid' },
  { id: 2, value: ['pending'], name: 'Pakkumised' },
  { id: 3, value: ['ongoing', 'finished'], name: 'Töös' },
  { id: 4, value: ['approved', 'rejected'], name: 'Arhiiv' },
];

export const statusDefinitions = {
  applied: 'Esitatud',
  rejected: 'Keeldutud',
  pending: 'Pakkumisel',
  ongoing: 'Töös',
  finished: 'Lõpetatud',
  approved: 'Kinnitatud',
};
export const filterStatusDefinitions = {
  applied: 'applied',
  rejected: 'rejected',
  pending: 'pending',
  ongoing: 'ongoing',
  finished: 'finished',
  approved: 'approved',
};

export const buildingTypes = [
  { id: 0, name: '' },
  { id: 1, name: 'Rehemaja' },
  { id: 2, name: 'Elamu' },
  { id: 3, name: 'Ait' },
  { id: 4, name: 'Kelder' },
  { id: 5, name: 'Koosehitis' },
  { id: 6, name: 'Kuur' },
  { id: 7, name: 'Laut' },
  { id: 8, name: 'Saun' },
  { id: 9, name: 'Suveköök' },
  { id: 10, name: 'Rehi' },
  { id: 11, name: 'Sepikoda' },
  { id: 12, name: 'Kuivati' },
  { id: 13, name: 'Küün' },
  { id: 14, name: 'Tuulik' },
  { id: 15, name: 'Vesiveski' },
  { id: 16, name: 'muu' },
];

export const buildingStructures = [
  { id: 0, name: '' },
  { id: 1, name: 'rõhtpalk' },
  { id: 2, name: 'püstpalk' },
  { id: 3, name: 'sõrestik' },
  { id: 4, name: 'tellis' },
  { id: 5, name: 'savi' },
  { id: 6, name: 'maakivi' },
  { id: 7, name: 'paekivi' },
  { id: 8, name: 'muu' },
];

export const detailTypes = [
  { id: 0, name: '' },
  { id: 1, name: 'vundament' },
  { id: 2, name: 'põrand' },
  { id: 3, name: 'lagi' },
  { id: 4, name: 'vahelagi' },
  { id: 5, name: 'sein' },
  { id: 6, name: 'katus' },
  { id: 7, name: 'küttekeha' },
  { id: 8, name: 'korsten' },
  { id: 9, name: 'aken' },
  { id: 10, name: 'uks' },
  { id: 11, name: 'rõdu' },
  { id: 12, name: 'tala/post' },
  { id: 13, name: 'trepp' },
  { id: 14, name: 'muu' },
];

export const detailMaterials = [
  { id: 0, name: '' },
  { id: 1, name: 'komposiit' },
  { id: 2, name: 'palk' },
  { id: 3, name: 'puit' },
  { id: 4, name: 'betoon' },
  { id: 5, name: 'maakivi' },
  { id: 6, name: 'paekivi' },
  { id: 7, name: 'telliskivi' },
  { id: 8, name: 'liiv' },
  { id: 9, name: 'puitlaast' },
  { id: 10, name: 'pilliroog' },
  { id: 11, name: 'eterniit' },
  { id: 12, name: 'plekk' },
  { id: 13, name: 'pinnas' },
  { id: 14, name: 'muu' },
];

export const DEFAULT_STATUS_ID_ARRAY = [1, 2, 3];
export const ALL_STATUSES = ['applied', 'pending', 'ongoing', 'finished'];
export const CONSULTATIONS_DEFAULT_SORT_OPTIONS = [{
  dataField: 'update_time',
  order: 'desc',
}];
