import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  Button,
  ButtonGroup,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Glyphicon,
  Grid,
  Panel,
  Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { detailMaterials, detailTypes, safeParseInt } from '../../../utils/constants';
import ToolTip from '../../../utils/ToolTip';
import { nextId, removeIdPrefix } from '../../../utils/id-generator';
import Images from '../../Images/components/Images';
import { getTextareaHeight } from '../../../utils/helpers';

const Detail = ({
  detail,
  index,
  deleteSpecificDetail,
  handleDetailChange,
  isDetailsEditingDisabled,
}) => {
  const [descriptionHeight, setDescriptionHeight] = useState('50px');
  const [descriptionId] = useState(nextId('description'));
  const [recommendationHeight, setRecommendationHeight] = useState('50px');
  const [recommendationId] = useState(nextId('recommendation'));

  useEffect(() => {
    setDescriptionHeight(getTextareaHeight({
      id: descriptionId,
    }));
  }, [detail.description, descriptionId]);

  useEffect(() => {
    setRecommendationHeight(getTextareaHeight({
      id: recommendationId,
    }));
  }, [detail.recommendation, recommendationId]);

  const handleChange = useCallback((event) => {
    const { value } = event.target;
    const target = removeIdPrefix(event.target.id);
    const detailChanges = {};

    if (target === 'detail_type_id') {
      const id = safeParseInt(value, 0);
      const detailType = detailTypes.find((type) => type.id === id);

      detailChanges[target] = value;
      detailChanges.detail_type_name = detailType && detailType.name;
    } else if (target === 'material_id') {
      const id = safeParseInt(value, 0);
      const detailMaterial = detailMaterials.find((type) => type.id === id);

      detailChanges[target] = value;
      detailChanges.material_name = detailMaterial && detailMaterial.name;
    } else {
      detailChanges[target] = value;
    }

    handleDetailChange({
      key: index, detail: { ...detail, ...detailChanges },
    });
  }, [handleDetailChange, index, detail]);

  return (
    <div>
      <Panel defaultExpanded>
        <Panel.Heading>
          <Grid fluid>
            <Row>
              <Col md={11}>
                <Panel.Title
                  toggle={!isDetailsEditingDisabled}
                >
                  {`${index + 1}. Kahjustus`}
                </Panel.Title>
              </Col>
              <Col md={1}>
                {!isDetailsEditingDisabled && (
                <DeleteDetailActionButton
                  deleteSpecificDetail={deleteSpecificDetail}
                  isDetailsEditingDisabled={isDetailsEditingDisabled}
                />
                )}
              </Col>
            </Row>
          </Grid>
        </Panel.Heading>
        <Panel.Body collapsible>
          <Form componentClass="div" inline>
            <FormGroup controlId={nextId('detail_type_id')}>
              <ControlLabel style={{ margin: '2px' }}>Kahjustunud detaili tüüp: </ControlLabel>
              <FormControl
                componentClass="select"
                value={detail.detail_type_id || 0}
                onChange={handleChange}
                disabled={isDetailsEditingDisabled}
                style={{ margin: '2px', marginRight: '6px' }}
              >
                {detailTypes.map((type) => (
                  <option value={type.id} key={type.id}>{type.name}</option>
                ))}
              </FormControl>
            </FormGroup>
            <FormGroup controlId={nextId('material_id')}>
              <ControlLabel style={{ margin: '2px' }}>Detaili materjal: </ControlLabel>
              <FormControl
                componentClass="select"
                value={detail.material_id || 0}
                onChange={handleChange}
                disabled={isDetailsEditingDisabled}
                style={{ margin: '2px', marginRight: '6px' }}
              >
                {detailMaterials.map((type) => (
                  <option value={type.id} key={type.id}>{type.name}</option>
                ))}
              </FormControl>
            </FormGroup>
          </Form>
          <br />
          <FormGroup controlId={nextId('images')}>
            <Images
              title="Kahjustuse pildid"
              maxLength={5}
              handleParentChange={handleDetailChange}
              parentElement={detail}
              parentKey={index}
              images={detail.files || []}
              parentElementType="detail"
              isDetailsEditingDisabled={isDetailsEditingDisabled}
            />
          </FormGroup>
          <FormGroup controlId={descriptionId}>
            <ControlLabel>Kahjustuse kirjeldus: </ControlLabel>
            <FormControl
              value={detail.description || ''}
              onChange={handleChange}
              componentClass="textarea"
              disabled={isDetailsEditingDisabled}
              style={{
                minWidth: '100%',
                maxWidth: '100%',
                width: '100%',
                minHeight: '50px',
                height: descriptionHeight,
              }}
            />
          </FormGroup>
          <FormGroup controlId={recommendationId}>
            <ControlLabel>Soovitus: </ControlLabel>
            <FormControl
              value={detail.recommendation || ''}
              onChange={handleChange}
              componentClass="textarea"
              disabled={isDetailsEditingDisabled}
              style={{
                minWidth: '100%',
                maxWidth: '100%',
                width: '100%',
                minHeight: '50px',
                height: recommendationHeight,
              }}
            />
          </FormGroup>
        </Panel.Body>
      </Panel>
    </div>
  );
};

Detail.propTypes = {
  detail: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
  deleteSpecificDetail: PropTypes.func.isRequired,
  handleDetailChange: PropTypes.func.isRequired,
  isDetailsEditingDisabled: PropTypes.bool.isRequired,
};

const DeleteDetailActionButton = ({ deleteSpecificDetail, isDetailsEditingDisabled }) => (
  <ButtonGroup bsSize="xsmall" vertical>
    {ToolTip(
      'button-delete-detail',
      <Button
        /* eslint-disable-next-line consistent-return */
        onClick={() => {
          // eslint-disable-next-line no-alert
          if (window.confirm('Kindel et soovite detaili/kahjustust kustutada?')) {
            return deleteSpecificDetail();
          }
        }}
        disabled={isDetailsEditingDisabled}
      >
        <Glyphicon glyph="glyphicon glyphicon-trash" />
      </Button>,
    )}
  </ButtonGroup>
);

DeleteDetailActionButton.propTypes = {
  deleteSpecificDetail: PropTypes.func.isRequired,
  isDetailsEditingDisabled: PropTypes.bool.isRequired,
};

export default Detail;
