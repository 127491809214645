/* eslint-disable max-len */
import React from 'react';
import {
  Button, Glyphicon, Panel, PanelGroup, Thumbnail,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const HelpPages = () => {
  const history = useHistory();

  return (
    <div
      id="HelpPages"
      style={{
        margin: '0 auto',
        width: '90%',
        minWidth: '380px',
        padding: '20px',
        borderRadius: '3px',
        border: '1px solid #ccc',
      }}
    >
      <Button onClick={() => history.goBack()}>Tagasi</Button>
      <br />
      <br />
      <PanelGroup accordion id="help_page" defaultActiveKey="a">
        <Panel eventKey="a">
          <Panel.Heading>
            <Panel.Title toggle componentClass="h2">Peavaade - Nõustamiste nimekiri</Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible>
            <Thumbnail
              src="http://d2cjc9vixig1mj.cloudfront.net/public/helppage_main_view.jpg"
              alt="main_view_screenshot.jpg"
            />
            <PanelGroup accordion id="main_view" defaultActiveKey="a1">
              <Panel eventKey="a1">
                <Panel.Heading>
                  <Panel.Title toggle componentClass="h2">1. Menüü</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <p>
                    <b>Nõustamised</b>
                    {' '}
                    - Navigeeri peavaatesse ehk nõustamise nimekirja juurde.
                    <br />
                    <b>Kasutajad</b>
                    {' '}
                    - Menüü element ja vaade on nähtav ainult admin kasutajatele.
                    Navigeeri kasutajate nimekirja juurde.
                    <br />
                    <b>Konsultandid</b>
                    {' '}
                    - Menüü element ja vaade ei ole nähetav konsultantidele.
                    Navigeeri konsultantide nimekirja juurde.
                    <br />
                    <b>Profiil</b>
                    {' '}
                    - Navigeeri enda profiili detail vaatesse.
                    <br />
                    <b>Logi välja</b>
                    {' '}
                    - Logi välja ja kustuta käimasolev sessioon.
                    <br />
                    <b>Küsimärk</b>
                    {' '}
                    - käesolev abi/juhendi lehekülg
                    <br />
                  </p>
                </Panel.Body>
              </Panel>
              <Panel eventKey="a2">
                <Panel.Heading>
                  <Panel.Title toggle componentClass="h2">
                    2. Nõustamiste filtreerimine staatuse
                    järgi
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <p>
                    Vaates näidatakse ainult selliseid konsultatsioone, millede staatused on antud
                    valikust aktiveeritud (tumedam allavajutatud hall).
                    <br />
                    <b>Kõik kiirvalik</b>
                    {' '}
                    - Aktiveerib filtrite vaikeväärtuse - Soovid, Pakkumised ja Töös
                    filtrid
                    <br />
                    <b>Soovid filter</b>
                    {' '}
                    - Uued sisse tulnud nõustamised, mille staatus on
                    &quot;Esitatud&quot;.
                    <br />
                    <b>Pakkumised filter</b>
                    {' '}
                    - Nõustajatele kinnitamiseks saadetud nõustamised, mille
                    staatus on &quot;Pakkumisel&quot;.
                    <br />
                    <b>Töös filter</b>
                    {' '}
                    - Hetkel töös olevad nõustamised, millede staatus on &quot;Töös&quot;
                    või &quot;Lõpetatud&quot;.
                    <br />
                    <b>Arhiiv filter</b>
                    {' '}
                    - Kinnitatud ja keeldutud nõustamised, mida konsultandid enam muuta
                    ei saa. Nende nõustamiste staatus
                    on &quot;Kinnitatud&quot; või &quot;Keeldutud&quot;.
                    <br />
                  </p>
                </Panel.Body>
              </Panel>
              <Panel eventKey="a3">
                <Panel.Heading>
                  <Panel.Title toggle componentClass="h2">3. Nõustamiste otsimine</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <p>
                    Filtreeri vaates nähtaval olevaid nõustamisi selle järgi, kas nad sisaldavad
                    otsingufraasi. Fraasi vastet proovitakse leida järgnevate konsultatsiooni
                    väljadest:
                    staatus; katastritunnus; aadressi väljad; kirjeldus; kliendi e-post ja telefon;
                    konsultandi nimi, telefon, e-post ja firma nimi. Konsultatsiooni detailandmete
                    järgi
                    (nagu ehitus aasta või hoonete andmed) hetkel konsultatsiooni otsida EI saa.
                  </p>
                </Panel.Body>
              </Panel>
              <Panel eventKey="a4">
                <Panel.Heading>
                  <Panel.Title toggle componentClass="h2">
                    4. Ekspordi nõustamised csv
                    faili
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <p>
                    Kõik kätte saadavad nõustamised on võimalik exportida csv tüüpi faili
                    (ingl.k.
                    <i>comma separated values</i>
                    {' '}
                    ehk komaga eraldatud väärtused).
                    <br />
                    <br />
                    <b>Juheend, kuidas CSV fail importida exceli faili:</b>
                    <br />
                    Klõpsake Excelis nuppu &quot;Fail &gt; Uus&quot;.
                    Klõpsake vahekaarti &quot;Andmed&quot;.

                    <b>Exceli vanemate versioonide jaoks:</b>
                    <br />
                    Klõpsake suvandit &quot;Tekstist&quot;. Valige CSV, mida soovite importida.
                    Excel kuvab &quot;Tekstiimpordi viisard&quot;.
                    <br />
                    <br />

                    Selle viisardi 1. etapis:
                    <br />
                    Valige raadionupp &quot;Eraldatud&quot;.
                    Väljal &quot;Faili päritolu&quot; valige &quot;65001: Unicode (UTF-8)&quot;.
                    Klõpsake nuppu &quot;Järgmine&quot;.
                    <br />
                    <br />

                    Selle viisardi 2. etapis:
                    <br />
                    Valige märkeruut „Komaga”.
                    Klõpsake nuppu &quot;Lõpeta&quot;.
                    Ilmuvas dialoogiaknas klõpsake nuppu &quot;OK&quot;.
                    <br />
                    <br />

                    <b>Exceli uuemate versioonide jaoks:</b>
                    <br />
                    Klõpsake suvandit „Tekstist/CSV-st”. Valige CSV, mida soovite importida.
                    Järgmises kastis valige: Väljal &quot;Faili päritolu&quot; valige
                    &quot;65001: Unicode (UTF-8)&quot;.
                    Klõpsake nuppu &quot;Laadi&quot;.
                  </p>
                </Panel.Body>
              </Panel>
              <Panel eventKey="a5">
                <Panel.Heading>
                  <Panel.Title toggle componentClass="h2">5. Uue nõustamise lisamine</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <p>
                    Nupul klõpsamine viib uue nõustamise lisamise vaatesse. Konsultantide poolt
                    loodud nõustamised määratakse atomaatselt neile endile ning pannakse staatusesse
                    &quot;Töös&quot;. Kasutajate poolt loodud nõustamistele konsultanti ei
                    määrata ning need pannakse staatusesse &quot;Esitatud&quot;
                  </p>
                </Panel.Body>
              </Panel>
              <Panel eventKey="a6">
                <Panel.Heading>
                  <Panel.Title toggle componentClass="h2">6. Näita detaile</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <p>
                    Nendest nuppudest on võimalik laiendada nõustamise kohta näidatavat
                    informatsiooni. Laiendatud olekus kuvatakse konsultatsiooni asukoha ja
                    konsulteerija täpsemad andmed ning kirjeldus.
                  </p>
                </Panel.Body>
              </Panel>
              <Panel eventKey="a7">
                <Panel.Heading>
                  <Panel.Title toggle componentClass="h2">7. Nõustamise staatus</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <p>
                    <b>Esitatud</b>
                    {' '}
                    - Uued nõustamise soovid, millele ei ole nõustajat veel määratud
                    <br />
                    <b>Pakkumisel</b>
                    {' '}
                    - Nõustamised, millele on nõustaja määratud ja e-post pakkumisega
                    saadetud, kuid mida nõustaja ei ole veel töösse võtnud.
                    <br />
                    <b>Töös</b>
                    {' '}
                    - Nõustamised mis on hetkel konsultandi poolt tegemisel
                    <br />
                    <b>Lõpetatud</b>
                    {' '}
                    - Nõustamine on konsultandi poolt tehtud, detailid täidetud ning on
                    edastatud administraatoritele kinnitamiseks.
                    <br />
                    <b>Kinnitatud</b>
                    {' '}
                    - Töö on tehtud ja kinnitatud. Avalik raport on valmis ning
                    osapooltele edastatud. Detailide muutmine ei ole enam võimalik.
                    <br />
                    <b>Keeldutud</b>
                    {' '}
                    - Administraatorid on otsustanud antud nõustamist mitte teostada.
                    <br />
                  </p>
                </Panel.Body>
              </Panel>
              <Panel eventKey="a8">
                <Panel.Heading>
                  <Panel.Title toggle componentClass="h2">
                    8. Nõustaja lisamine ja
                    eemaldamine
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <p>
                    Kui nõustamisel veel konsultanti ei ole saab selle pluss märki vajutades lisada.
                    Pluss märgil vajutades avaneb konsultandi lisamise valik:
                  </p>
                  <Thumbnail
                    src="http://d2cjc9vixig1mj.cloudfront.net/public/add_consultant_modal.png"
                    alt="add_consultant_modal.png"
                  />
                  <p>
                    Lisaks konsultandi valimisele on võimalik valida, kas konsultandilt küsitakse
                    kinnitust või mitte -
                    {' '}
                    <b>&quot;Küsi konsulteerija kinnitust&quot;</b>
                    {' '}
                    kast.
                    Kui see on aktiivne, siis saadetakse konsultandile kinnitus e-post ning
                    nõustamise staatus muutub &quot;Pakkumisel&quot;. Kui seda märget ei ole,
                    siis muudetakse nõustamise staatus &quot;Töös&quot; ning e-posti ei saadeta.
                    <br />
                    Kui nõustamisele on juba määratud konsultant, siis saab tema miinus märgist
                    eemaldada.
                  </p>
                </Panel.Body>
              </Panel>
              <Panel eventKey="a9">
                <Panel.Heading>
                  <Panel.Title toggle componentClass="h2">9. Nõustamisega seotud tegevused</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <p>
                    Konsultandile on kätte saadavad ainult &quot;Nõustamise detailid/muutmine&quot; ja
                    &quot;Kinnita viimane tegevus&quot; tegevused. Hall mitteaktiivne nupp tähendab,
                    et antud tegevust ei ole võimalik vastaval nõustamisel teostada.
                    <br />
                    <br />
                    <Glyphicon glyph="glyphicon glyphicon-edit" />
                    :
                    <b>Nõustamise detailid/muutmine</b>
                    {' '}
                    -
                    Nupul klõpsamine viib nõustamise detail vaatesse
                    <br />
                    <Glyphicon glyph="glyphicon glyphicon-ok-sign" />
                    :
                    <b>Kinnita viimane tegevus</b>
                    {' '}
                    -
                    Paneb nõustamise järgmisesse staadiumisse: &quot;Pakkumisel&quot; =&gt;
                    &quot;Töös&quot;; &quot;Töös&quot; =&gt; &quot;Lõpetatud&quot; ja
                    &quot;Lõpetatud&quot; =&gt; &quot;Kinnitatud&quot;
                    <br />
                    <Glyphicon glyph="glyphicon glyphicon-remove-sign" />
                    :
                    <b>Lükka viimane tegevus tagasi</b>
                    {' '}
                    -
                    Paneb nõustamise eelmisesse
                    staadiumisse: &quot;Lõpetatud&quot; =&gt; &quot;Töös&quot;
                    ja &quot;Kinnitatud&quot; =&gt; &quot;Lõpetatud&quot;
                    <br />
                    <Glyphicon glyph="glyphicon glyphicon-minus-sign" />
                    :
                    <b>Keeldu nõustaminsest</b>
                    {' '}
                    -
                    Määra nõustamise staatuseks &quot;Keeldutud&quot;
                    <br />
                    <Glyphicon glyph="glyphicon glyphicon-trash" />
                    :
                    <b>Kustuta nõustamine</b>
                    {' '}
                    -
                    Kustutab nõustamise ning sellega seotud detailid. Andmeid lõplikult ei kustutata
                    aga
                    nõustamist ei kuvata enam süsteemis ja seda ei ole võimalik rakendusest taastada.
                    <br />
                    <Glyphicon glyph="glyphicon glyphicon-retweet" />
                    :
                    <b>Nõustamise lähtestamine</b>
                    {' '}
                    -
                    kustutab konsultandi poolt sisestatud andmed (ehitised, kahjustused ja pildid),
                    eemaldab konsultandi nõustamisest ja seab konsultatsiooni staatuseks
                    &quot;Esitatud&quot;.
                    <br />
                  </p>
                </Panel.Body>
              </Panel>
            </PanelGroup>
          </Panel.Body>
        </Panel>
        <Panel eventKey="b">
          <Panel.Heading>
            <Panel.Title toggle componentClass="h2">Nõustamise lisamine</Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible>
            <Thumbnail
              src="http://d2cjc9vixig1mj.cloudfront.net/public/new_consultation.png"
              alt="new_consultation.png"
            />
            <p>
              Nõustamise lisamisel on kolm kohustuslikku välja mis alati täitma peab:
              <br />
              <b>Katastritunnus</b>
              {' '}
              - Igal nõustamisel peab olema määratud reaalselt eksisteeriv
              katastritunnus. Maamaja katastritunnuse saab järgi vaadata
              <a href="https://xgis.maaamet.ee/xgis2/page/app/maainfo"> maaametist</a>
              <br />
              <b>Kirjeldus</b>
              {' '}
              - Igal nõustamisel peab olema (kliendi poolt antud) kirjeldus, kus
              märgitakse ära, mille kohta nõu soovitakse.
              <br />
              <b>Kliendi e-post</b>
              {' '}
              - Igal nõustamisel peab olema määratud kliendi e-post - valiidne
              e-posti aadress, kuhu nõustamise lõppedes saadetakse ka raport.
              <br />
              <br />
              Lisaks on võimalik sisestada asukoha põhiseid märkmeid (näiteks kuidas kohale jõuda) ja
              kliendi telefoni numbrit.
              <br />
            </p>
          </Panel.Body>
        </Panel>
        <Panel eventKey="c">
          <Panel.Heading>
            <Panel.Title toggle componentClass="h2">Nõustamise detailvaade</Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible>
            <Thumbnail
              src="http://d2cjc9vixig1mj.cloudfront.net/public/edit_consultation_details.jpg"
              alt="edit_consultation_details.jpg"
            />
            <p>
              Nõustamise detailvaates on lisaks lisamisel kirjeldatud väljadele võimalik nõustamisele
              lisada hooneid, hoonetele kahjustusi ning hoonetele ja kahjustustele pilte. Juba loodud
              nõustamise katastri tunnust hiljem muuta ei saa, küll aga saab redigeerida nõustamise
              kirjeldust, asukoha kirjeldust ja kliendi e-post ning telefoni numbrit.
              <br />
            </p>
            <PanelGroup accordion id="detail_view" defaultActiveKey="c1">
              <Panel eventKey="c1">
                <Panel.Heading>
                  <Panel.Title toggle componentClass="h2">1. Hooned</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <p>
                    Igale nõustamisele on võimalik lisada hooneid. Hoonete kirjeldamiseks on võimalik
                    valida hoone tüüp, põhi tarind ning ehitamise aasta ja lisada lühike kirjeldus.
                    <br />
                  </p>
                </Panel.Body>
              </Panel>
              <Panel eventKey="c2">
                <Panel.Heading>
                  <Panel.Title toggle componentClass="h2">2. Hoone eemaldamine</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <p>
                    Antud nupul klõpsates eemaldatakse nõustamiselt hoone, sellega seeotud kahjustused
                    ning
                    kõik pildid.
                    <br />
                  </p>
                </Panel.Body>
              </Panel>
              <Panel eventKey="c3">
                <Panel.Heading>
                  <Panel.Title toggle componentClass="h2">3. Lisatud pildid</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <p>
                    Pärast pildi edukat lisamist kuvatakse see kohe ka nõustamise vaates. Igale
                    hoonele on
                    võimalik lisada kuni 10 ja kahjustusele kuni 5 pilti.
                  </p>
                </Panel.Body>
              </Panel>
              <Panel eventKey="c4">
                <Panel.Heading>
                  <Panel.Title toggle componentClass="h2">4. Pildi eemaldamine</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <p>
                    Antud nupule klõpsates eemaldatakse pilt ning sellega seotud meta-andmed
                    (kirjeldus)
                    nõustamiselt.
                  </p>
                </Panel.Body>
              </Panel>
              <Panel eventKey="c5">
                <Panel.Heading>
                  <Panel.Title toggle componentClass="h2">5. Pildi kirjeldus</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <p>
                    Igale pildile on võimalik lisada lühike, kuni 100 tähemärgine kirjeldus. Pikema
                    seletuse võib vajaduse korral lisada kahjustuse või hoone enda kirjelduses.
                  </p>
                </Panel.Body>
              </Panel>
              <Panel eventKey="c6">
                <Panel.Heading>
                  <Panel.Title toggle componentClass="h2">6. Pildi lisamine</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <p>
                    Antud hallil alal klõpsates avaneb operatsiooni süsteemi faili sirvija, kust on
                    võimalik valida hoonele või kahjustusele lisamiseks pildi faile. Alternatiivselt
                    võib
                    faile lisada neid sellel hallile alale lohistades. Aksepteeritavad faili laiendid
                    ehk
                    faili nime lõpud on &quot;.png&quot;, &quot;.jpg&quot;, &quot;.gif&quot; ja
                    &quot;.jpeg&quot;. Maksimaalne lubatav faili suurus on 10MB. Igale hoonele on
                    võimalik lisada kuni 10 ja kahjustusele kuni 5 pilti.
                  </p>
                </Panel.Body>
              </Panel>
              <Panel eventKey="c7">
                <Panel.Heading>
                  <Panel.Title toggle componentClass="h2">7. Kahjustused</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <p>
                    Igale hoonele on võimalik lisada üks või mitu kahjustust. Kahjustuse kirjeldamisel
                    on
                    võimalik määrata kahjustunud detaili tüüp ja materjal, kirjeldada kahjustust ning
                    anda kliendile soovitusi kahjustuse eemaldamiseks, parandamiseks, renoveerimiseks
                    jne.
                  </p>
                </Panel.Body>
              </Panel>
              <Panel eventKey="c8">
                <Panel.Heading>
                  <Panel.Title toggle componentClass="h2">8. Kahjustuse eemaldamine</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <p>
                    Antud nupule klõpsates eemaldatakse kahjustus ning sellega seotud pildid
                    nõustamiselt
                  </p>
                </Panel.Body>
              </Panel>
              <Panel eventKey="c9">
                <Panel.Heading>
                  <Panel.Title toggle componentClass="h2">9. Nõustamisega seotud tegevused</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <p>
                    &quot;Lisa uus kahjustus&quot; ja &quot;Lisa uus hoone&quot; nupud vastavalt uute
                    hoonete ja kahjustuste lisamiseks. Igal hoonel on oma &quot;Lisa uus
                    kahjustus&quot;
                    nupp mis lisab kahjustuse vastavale hoonele.
                    <br />
                    <br />

                    &quot;Salvesta andmed&quot; nupule klõpsates salvestatakse kõik nõustamise
                    detailvaates sisestatud andmed, sealhulgas ka üles laetud failid. Kui andmed on
                    edukalt salvestatud ilmub roheline kinnitus-riba, millel on
                    nupp &quot;Tagasi&quot;,
                    mida vajutades on võimalik liikuda tagasi eelmisesse vaatesse:
                    <br />
                    <Thumbnail
                      src="http://d2cjc9vixig1mj.cloudfront.net/public/changes_saved.png"
                      alt="changes_saved.png"
                    />
                    Kui kinnitust ei ilmu 10 sekundi jooksul ja/või menüü alla ilmub punane vea teade
                    on midagi valesti läinud. Sel juhul on võimalik proovida andmeid muuta, pilte
                    eemaldada ja salvestamist uuesti proovida.
                    {' '}
                    <b>NB!</b>
                    {' '}
                    Salvestamata andmed lähevad
                    lehelt minema navigeerides kaotsi
                    <br />
                    <br />

                    Olenevalt sisse loginud kasutajast ja nõustamise olekust on lisaks salvestamise
                    nupule
                    võimalik näha nuppu &quot;Esita kinnitamiseks&quot; nuppu, mis funktsioneerib
                    samalaadselt nõustamise nimekirja vaates olevale &quot;Kinnita viimane
                    tegevus&quot;
                    nupule. Sellele nupule vajutades viiakse nõustamine
                    staatusesse &quot;Lõpetatud&quot;.
                    <b>NB!</b>
                    {' '}
                    &quot;Lõpetatud&quot; staatusega nõustamist konsultandid enam muuta ei
                    saa!
                    <br />
                    <Thumbnail
                      src="http://d2cjc9vixig1mj.cloudfront.net/public/save_consultation_button1.png"
                      alt="save_consultation_button1.png"
                    />
                    <Thumbnail
                      src="http://d2cjc9vixig1mj.cloudfront.net/public/save_consultation_button2.png"
                      alt="save_consultation_button2.png"
                    />
                  </p>
                </Panel.Body>
              </Panel>
            </PanelGroup>
          </Panel.Body>
        </Panel>
        <Panel eventKey="d">
          <Panel.Heading>
            <Panel.Title toggle componentClass="h2">Kasutajate ja konsultantide vaade</Panel.Title>
          </Panel.Heading>
          <Panel.Body collapsible>
            <Thumbnail
              src="http://d2cjc9vixig1mj.cloudfront.net/public/users_consultants.jpg"
              alt="users_consultants.jpg"
            />
            Kasutajate ja konsultantide nimekirjavaadete puhul on võimalik muudetavate väljade peale
            topeltklõpsates välja väärtusi muuta. Muudetavateks väljadeks on &quot;Nimi&quot;,
            &quot;Telefon&quot;, &quot;Asutus&quot;, &quot;Kirjeldus&quot; ja &quot;Maakonnad&quot;.
            <br />
            <PanelGroup accordion id="main_view">
              <Panel eventKey="d1">
                <Panel.Heading>
                  <Panel.Title toggle componentClass="h2">1. Otsing</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <p>
                    Filtreeri vaates nähtaval olevaid kasutajaid/nõustajaid selle järgi, kas nende
                    andmetes sisaldub otsingufraas.
                  </p>
                </Panel.Body>
              </Panel>
              <Panel eventKey="d2">
                <Panel.Heading>
                  <Panel.Title toggle componentClass="h2">2. E-post</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <p>
                    Igal kasutajal on unikaalne e-posti aadress, mida muuta ei ole võimalik. Kui
                    kasutaja
                    või nõustaja e-post muutub, siis on vajalik uue loomine või selle muudatuse
                    tegemine
                    otse andmebaasis.
                  </p>
                </Panel.Body>
              </Panel>
              <Panel eventKey="d3">
                <Panel.Heading>
                  <Panel.Title toggle componentClass="h2">3. Email kinnitatud</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <p>
                    Antud väli näitab, et kasutaja või nõustaja on oma tunnustega süsteemi sisse
                    kirjutanud ning sellega kinnitanud oma nõusolekut SA Eesti Vabaõhumuuseumi
                    Maaarhitektuuri keskuse
                    <a href="http://maaarhitektuur.blogspot.com/p/privaatsus.html">
                      privaatsuspoliitikaga
                    </a>
                    . Ilma vastava kinnituseta ei ole võimalik neile süsteemi
                    kaudu e-poste saata.
                  </p>
                </Panel.Body>
              </Panel>
              <Panel eventKey="d4">
                <Panel.Heading>
                  <Panel.Title toggle componentClass="h2">4. Teavitused e-postiga</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <p>
                    Väli mida iga kasutaja saab oma profiili alt aktiveerida. Kasutajatele, kellel on
                    see aktiveeritud, saadetakse uute nõustamiste, keeldutud nõustamiste jne. kohta
                    teavitusi.
                  </p>
                </Panel.Body>
              </Panel>
              <Panel eventKey="d5">
                <Panel.Heading>
                  <Panel.Title toggle componentClass="h2">5. Administraator indikaator</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <p>
                    Kastike, mis näitab kas kasutaja puhul on tegu administraatoriga või
                    tavakasutajaga.
                    Antud välja saab valida ainult kasutajat luues hiljem seda muuta ei saa.
                    Administraatoritel on ainsana õigus luua ja hallata uusi kasutajaid.
                  </p>
                </Panel.Body>
              </Panel>
              <Panel eventKey="d6">
                <Panel.Heading>
                  <Panel.Title toggle componentClass="h2">
                    6. Kasutaja/Nõustajaga seotud
                    tegevused
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <p>
                    Nupud kasutaja/nõustajaga seotud tegevusete teostamiseks. Hall mitteaktiivne nupp
                    tähendab, et antud tegevust ei ole võimalik vastaval kasutaja/nõustaja puhul
                    teostada.
                    <br />
                    <br />

                    <Glyphicon glyph="glyphicon glyphicon-trash" />
                    :
                    <b>Kustuta kasutaja/nõustaja</b>
                    {' '}
                    -
                    Kustutab kasutaja/nõustaja. Kõik kasutajaga seotud nõustamised jäävad siiski
                    alles ning samasse staadiumisse.
                    <br />

                    <Glyphicon glyph="glyphicon glyphicon-envelope" />
                    :
                    <b>Saada konto loomise email uuesti.</b>
                    {' '}
                    -
                    Saadab kasutajale/nõustajale uuesti emaili sisse logimiseks vajaliku teabega.
                    Kasutajatele/nõustajatele kelle email on juba kinnitanud (nad on vähemalt korra
                    juba
                    sisse loginud) antud emaili uuesti saata ei saa.
                    <br />
                  </p>
                </Panel.Body>
              </Panel>
              <Panel eventKey="d7">
                <Panel.Heading>
                  <Panel.Title toggle componentClass="h2">7. Lisa uus kasutaja/nõustajaga</Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                  <p>
                    Nupul klõpsamine viib vastavalt kas uue kasutaja või nõustaja loomise lehele.
                    <br />
                  </p>
                </Panel.Body>
              </Panel>
            </PanelGroup>
          </Panel.Body>
        </Panel>
      </PanelGroup>
    </div>
  );
};

export default HelpPages;
