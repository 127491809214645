import React, { Fragment } from 'react';
import {
  arrayOf, node, oneOfType, array,
} from 'prop-types';

const Compose = ({ components = [], children }) => (
  <Fragment>
    {components.reduceRight(
      // eslint-disable-next-line react/jsx-props-no-spreading
      (acc, [Comp = () => null, props = {}]) => <Comp {...props}>{acc}</Comp>,
      children,
    )}
  </Fragment>
);

Compose.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  components: arrayOf(array).isRequired,
  children: oneOfType([arrayOf(node), node]).isRequired,
};

export default Compose;
