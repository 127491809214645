import { makeRequestActionTypes } from '../../../utils/helpers';

export const getConsultationsTypes = makeRequestActionTypes(
  'consultations/GET_CONSULTATIONS',
);

export const getConsultationTypes = makeRequestActionTypes(
  'consultations/GET_CONSULTATION',
);

export const getPublicConsultationTypes = makeRequestActionTypes(
  'consultations/GET_PUBLIC_CONSULTATION',
);

export const deleteConsultationTypes = makeRequestActionTypes(
  'consultations/DELETE_CONSULTATION',
);

export const updateConsultationTypes = makeRequestActionTypes(
  'consultations/UPDATE_CONSULTATION',
);

export const createConsultationTypes = makeRequestActionTypes(
  'consultations/CREATE_CONSULTATION',
);

export const confirmConsultationTypes = makeRequestActionTypes(
  'consultations/CONFIRM_CONSULTATION',
);

export const disproveConsultationTypes = makeRequestActionTypes(
  'consultations/DISPROVE_CONSULTATION',
);

export const rejectConsultationTypes = makeRequestActionTypes(
  'consultations/REJECT_CONSULTATION',
);

export const resetConsultationTypes = makeRequestActionTypes(
  'consultations/RESET_CONSULTATION',
);

export const removeConsultantFromConsultationTypes = makeRequestActionTypes(
  'consultations/REMOVE_CONSULTANT_FROM_CONSULTATION',
);

export const addConsultantToConsultationTypes = makeRequestActionTypes(
  'consultations/ADD_CONSULTANT_TO_CONSULTATION',
);

export const RESET_CONSULTATION_ERROR_STATE = 'consultations/RESET_CONSULTATION_ERROR_STATE';

export const RESET_CONSULTATIONS_ERROR_STATE = 'consultations/RESET_CONSULTATIONS_ERROR_STATE';
