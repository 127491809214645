import React, { useCallback } from 'react';
import { Button, ButtonGroup, Panel } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Detail from './Detail';

const Details = ({
  building,
  buildingKey,
  handleBuildingChange,
  isDetailsEditingDisabled,
}) => {
  const deleteDetail = useCallback((key) => function deleteSpecificDetail() {
    handleBuildingChange({
      key: buildingKey,
      building: {
        ...building,
        details: building.details.filter((_, index) => index !== key),
      },
    });
  }, [handleBuildingChange, buildingKey, building]);

  const addDetail = useCallback(() => {
    handleBuildingChange({
      key: buildingKey,
      building: {
        ...building,
        details: [...(building.details || []), {}],
      },
    });
  }, [building, buildingKey, handleBuildingChange]);

  const handleDetailChange = useCallback(({ key, detail }) => {
    const newBuilding = { ...building };

    newBuilding.details = [...(newBuilding.details || [])];
    newBuilding.details[key] = detail;

    handleBuildingChange({ key: buildingKey, building: newBuilding });
  }, [building, buildingKey, handleBuildingChange]);

  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>Kahjustused</Panel.Title>
      </Panel.Heading>
      {building.details && building.details.map((detail, index) => (
        <Detail
          detail={detail}
          /* eslint-disable-next-line react/no-array-index-key */
          key={index}
          index={index}
          deleteSpecificDetail={deleteDetail(index)}
          handleDetailChange={handleDetailChange}
          isDetailsEditingDisabled={isDetailsEditingDisabled}
        />
      ))}
      {!isDetailsEditingDisabled
        && (
        <ButtonGroup style={{ width: '100%' }}>
          <Button
            type="button"
            style={{ width: '100%' }}
            onClick={addDetail}
            disabled={isDetailsEditingDisabled}
          >
            Lisa uus kahjustus
          </Button>
        </ButtonGroup>
        )}
    </Panel>
  );
};

Details.propTypes = {
  building: PropTypes.shape({}).isRequired,
  buildingKey: PropTypes.number.isRequired,
  handleBuildingChange: PropTypes.func.isRequired,
  isDetailsEditingDisabled: PropTypes.bool.isRequired,
};

export default Details;
