import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';

import PageNotFound from './components/PageNotFound';
import Header from './components/Header/Header';

import Login from './modules/Auth/components/Login';
import Logout from './modules/Auth/components/Logout';
import Consultations from './modules/Consultations/components/Consultations';
import Consultation from './modules/Consultations/components/Consultation';
import NewConsultation from './modules/Consultations/components/NewConsultation';
import PublicConsultation from './modules/Consultations/components/PublicConsultation';
import Profile from './modules/Users/components/Profile';
import Users from './modules/Users/components/Users';
import NewUser from './modules/Users/components/NewUser';
import HelpPages from './modules/HelpPages/components/HelpPages';

import { selectIsUserLoggedIn } from './modules/Auth/redux/selectors';

const App = () => {
  const isUserLoggedIn = useSelector(selectIsUserLoggedIn);

  return (
    <div>
      <Helmet
        defaultTitle="Nõustamisteenuse haldussüsteem"
      />
      {isUserLoggedIn && <Header />}
      <div style={{ marginLeft: '1.5em', marginRight: '1.5em' }}>
        <Switch>
          {/** Public routes * */}
          <Route exact path="/public/reports/:consultationUuid" component={PublicConsultation} />
          <Route exact path="/public/404" component={PageNotFound} />

          {/** Auth module paths * */}
          <Route exact path="/login" component={Login} />
          {!isUserLoggedIn && <Redirect to="/login" />}
          <Route path="/logout" component={Logout} />

          {/** Help pages path * */}
          <Route exact path="/help" component={HelpPages} />

          {/** Consultations module paths * */}
          <Route exact path="/consultations" component={Consultations} />
          <Route exact path="/consultations/new" component={NewConsultation} />
          <Route exact path="/consultations/:consultationId" component={Consultation} />

          {/** Users module paths * */}
          <Route exact path="/users" render={() => (<Users type="user" />)} />
          <Route exact path="/users/new" render={() => (<NewUser type="user" />)} />

          <Route exact path="/consultants" render={() => (<Users type="consultant" />)} />
          <Route exact path="/consultants/new" render={() => (<NewUser type="consultant" />)} />

          <Route exact path="/profile" component={Profile} />

          {/** Default redirects back to consultations list view * */}
          <Redirect to="/consultations" />
        </Switch>
      </div>
    </div>
  );
};

export default App;
