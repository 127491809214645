const status = (response) => {
  if ((response.status >= 200 && response.status < 300) || response.status === 404) {
    return Promise.resolve(response);
  }

  return Promise.reject(response);
};

// eslint-disable-next-line prefer-promise-reject-errors
const handleError = (error) => Promise.reject({
  error,
});

const toJson = (response) => response.json()
  .then((parsedBody) => Promise.resolve({
    status: response.status,
    url: response.url,
    redirected: false,
    ...parsedBody,
  }));

const customApiHost = process.env.CUSTOM_API_HOST;

export default (url, options) => {
  const headers = {
    Accept: 'application/json',
    'Cache-control': 'no-cache',
    Pragma: 'no-cache',
  };

  if (!options || !options.withoutContentType) {
    headers['Content-Type'] = 'application/json';
  }

  const reqOptions = {
    headers,
    ...options,
  };

  return fetch(`${customApiHost || ''}${url}`, reqOptions)
    .then(toJson)
    .then(status)
    .then((res) => ({ data: res.data }))
    .catch(handleError);
};
