import React from 'react';
import { Alert } from 'react-bootstrap';
import {
  oneOfType, func, bool, object,
} from 'prop-types';

const DEFAULT_ERROR_TEXT = 'Midagi läks valesti. Palun laadige lehekülg uuesti!';

const ErrorBar = ({ error, resetErrorState }) => {
  let errorText = (error && error.error && error.error.publicError) || DEFAULT_ERROR_TEXT;

  if (error && error.error && error.error.message === 'Failed to fetch') {
    errorText = 'Ühendus serveriga ebaõnnestus! Palun veenduge, et teie internetiühendust on töökorras ning proovige uuesti.';
  }

  return (
    error
      ? (
        <div className="Error">
          {error && error.error
      && (
      <Alert bsStyle="danger" onClick={() => resetErrorState()}>
        <strong>{errorText}</strong>
      </Alert>
      )}
        </div>
      )
      : null
  );
};

ErrorBar.propTypes = {
  error: oneOfType([bool, object]).isRequired,
  resetErrorState: func.isRequired,
};

export default ErrorBar;
