const GLOBAL_PREFIX = 'id';

let lastId = 0;

const ID_PREFIX_SEPARATOR = '__';

export function nextId(name) {
  // eslint-disable-next-line no-plusplus
  lastId++;

  return `${GLOBAL_PREFIX}${lastId}${ID_PREFIX_SEPARATOR}${name}`;
}

export function removeIdPrefix(input) {
  const inputParts = input && input.split(ID_PREFIX_SEPARATOR);

  if (inputParts.length < 2) {
    return input;
  }

  inputParts.shift();

  return inputParts.join();
}
