import React, { useReducer, useCallback } from 'react';
import {
  Button, ControlLabel, FormControl, FormGroup, Checkbox,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { oneOf } from 'prop-types';
import { addUserActions, addConsultantActions } from '../redux/actions';
import { emailRegex } from '../../../utils/constants';

const NewUser = ({
  type,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [user, setUser] = useReducer((oldUser, changes) => ({ ...oldUser, ...changes }), {
    name: '',
    email: '',
    phone: '',
    counties: '',
    description: '',
    accountType: type,
    company_name: '',
  });

  const validateForm = useCallback(() => emailRegex.test(user.email), [user.email]);

  const handleChange = useCallback((event) => {
    setUser({
      [event.target.id]: event.target.value,
    });
  }, [setUser]);

  const handleIsAdminCheckboxChange = useCallback((event) => {
    setUser({
      accountType: event.target.checked ? 'admin' : 'user',
    });
  }, []);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();

    if (type === 'user') {
      dispatch(addUserActions.request(user));
    } else {
      dispatch(addConsultantActions.request(user));
    }

    history.goBack();
  }, [user, dispatch, history, type]);

  return (
    <div style={{
      margin: '0 auto', width: '90%', minWidth: '380px', padding: '20px', borderRadius: '3px', border: '1px solid #ccc',
    }}
    >
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="name">
          <ControlLabel>{`${type === 'user' ? 'Kasutaja' : 'Konsultandi'} nimi`}</ControlLabel>
          <FormControl
            type="text"
            autoComplete="name"
            value={user.name}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup
          controlId="email"
          validationState={validateForm() ? 'success' : 'error'}
        >
          <ControlLabel>E-post</ControlLabel>
          <FormControl
            value={user.email}
            onChange={handleChange}
            type="email"
          />
        </FormGroup>
        <FormGroup controlId="phone">
          <ControlLabel>Telefon</ControlLabel>
          <FormControl
            value={user.phone}
            onChange={handleChange}
            type="text"
          />
        </FormGroup>
        <FormGroup controlId="company_name">
          <ControlLabel>Asutuse nimi</ControlLabel>
          <FormControl
            autoFocus
            type="text"
            value={user.company_name}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup controlId="counties">
          <ControlLabel>Maakonnad</ControlLabel>
          <FormControl
            autoFocus
            type="text"
            value={user.counties}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup controlId="description">
          <ControlLabel>Kirjeldus</ControlLabel>
          <FormControl
            autoFocus
            value={user.description}
            onChange={handleChange}
            type="textarea"
            style={{
              minWidth: '100%', maxWidth: '100%', width: '100%', minHeight: '50px',
            }}
          />
        </FormGroup>
        {type === 'user' && (
        <FormGroup controlId="isAdmin">
          <ControlLabel>Admin kasutaja</ControlLabel>
          <Checkbox
            id="is_admin_flag"
            checked={user.accountType === 'admin'}
            onChange={handleIsAdminCheckboxChange}
          />
        </FormGroup>
        )}
        <hr />
        <Button
          block
          disabled={!validateForm()}
          type="submit"
        >
          {`Loo uus ${type === 'user' ? 'kasutaja' : 'konsultant'}`}
        </Button>
      </form>
    </div>
  );
};

NewUser.propTypes = {
  type: oneOf(['user', 'consultant']).isRequired,
};

export default NewUser;
