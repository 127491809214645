import React, {
  useState, useEffect, useCallback, useMemo, useReducer,
} from 'react';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import {
  bool, func, object, oneOfType,
} from 'prop-types';
import moment from 'moment';
import {
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  ButtonGroup,
  InputGroup,
  Form,
  Alert,
  Checkbox,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getConsultationActions,
  updateConsultationActions,
  confirmConsultationActions,
  resetConsultationErrorState,
} from '../redux/actions';
import {
  emailRegex,
  cadastralIdRegex,
} from '../../../utils/constants';
import Buildings from './Buildings';
import { selectIsConsultant } from '../../Auth/redux/selectors';
import {
  makeSelectGetConsultationById,
  selectConsultationRequestError,
  selectConsultationRequestLoading,
} from '../redux/selectors';
import { getTextareaHeight, useSelectorCreator } from '../../../utils/helpers';
import LoadingSpinner from '../../../components/LoadingSpinner';
import ErrorBar from '../../../components/ErrorBar/ErrorBar';

// eslint-disable-next-line complexity
const Consultation = ({
  isConsultant,
  isLoading,
  requestError,

  getConsultation,
  updateConsultation,
  confirmConsultation,
}) => {
  const { consultationId } = useParams();
  const history = useHistory();

  const [consultation, setConsultation] = useReducer((oldConsultations, changes) => ({
    ...oldConsultations,
    ...changes,
  }), {});
  const [descriptionHeight, setDescriptionHeight] = useState('50px');
  const [locationDescriptionHeight, setLocationDescriptionHeight] = useState('100px');
  const [isChangesSaved, setIsChangesSaved] = useState(true);
  const [copyButtonText, setCopyButtonText] = useState('Kopeeri');
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);
  const [isDetailsEditingDisabled, setIsDetailsEditingDisabled] = useState(true);
  const [skipEmails, setSkipEmails] = useState(false);

  const existingConsultation = useSelectorCreator(makeSelectGetConsultationById, consultationId);

  const publicURL = useMemo(
    () => `${window.location.origin}/public/reports/${existingConsultation && existingConsultation.uuid}`,
    [existingConsultation],
  );

  useEffect(() => {
    getConsultation(consultationId);
  }, [consultationId, getConsultation]);

  useEffect(() => {
    if (existingConsultation) {
      setConsultation(existingConsultation);
    }
  }, [existingConsultation]);

  useEffect(() => {
    setDescriptionHeight(getTextareaHeight({
      id: 'description',
    }));
  }, [consultation.description, isLoading]);

  useEffect(() => {
    setLocationDescriptionHeight(getTextareaHeight({
      id: 'location_description',
    }));
  }, [consultation.location_description, isLoading]);

  useEffect(() => {
    if (isLoading) {
      setIsDetailsEditingDisabled(true);

      return;
    }

    if (isConsultant) {
      setIsDetailsEditingDisabled(consultation.status !== 'ongoing');

      return;
    }

    setIsDetailsEditingDisabled(consultation.status === 'approved' || consultation.status === 'rejected');
  }, [consultation.status, isConsultant, isLoading]);

  const saveChangesToServer = useCallback((status) => {
    updateConsultation({
      id: consultationId,
      cadastral_id: consultation.cadastral_id,
      client_email: consultation.client_email,
      client_phone: consultation.client_phone,
      description: consultation.description,
      location_description: consultation.location_description,
      buildings: consultation.buildings,
      status: status || consultation.status,
      ...(status === 'finished' ? { work_end_time: moment.utc().format('YYYY-MM-DD HH:mm:ss') } : {}),
    });

    setIsSuccessMessageVisible(true);
    setIsChangesSaved(true);
  }, [
    consultation.buildings,
    consultation.cadastral_id,
    consultation.client_email,
    consultation.client_phone,
    consultation.description,
    consultation.location_description,
    consultation.status,
    consultationId,
    updateConsultation,
  ]);

  const copyPublicUrlToClipboard = useCallback(() => {
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(publicURL)
      .then(() => {
        setCopyButtonText('Kopeeritud!');
        setTimeout(() => {
          setCopyButtonText('Kopeeri');
        }, 2000);
      })
      .catch();
  }, [publicURL]);

  const handleChange = useCallback((event) => {
    const { id, value } = event.target;

    setIsChangesSaved(false);

    setConsultation({
      [id]: value,
    });
  }, [setConsultation, setIsChangesSaved]);

  const validateForm = useCallback(
    () => emailRegex.test(consultation.client_email)
            && cadastralIdRegex.test(consultation.cadastral_id)
            && !!consultation.description,
    [consultation.client_email, consultation.cadastral_id, consultation.description],
  );

  const toggleSkipEmails = () => {
    setSkipEmails(!skipEmails);
  };

  const handleConsultationBuildingsChange = useCallback((buildings) => {
    setConsultation({
      buildings: [...buildings],
    });
    setIsChangesSaved(false);
  }, [setConsultation, setIsChangesSaved]);

  const handleSubmitForConfirmation = useCallback((event) => {
    event.preventDefault();
    saveChangesToServer('finished');
  }, [saveChangesToServer]);

  return (
    isLoading && !existingConsultation.buildings
      ? (
        <Alert bsStyle="info">
          <div style={{ display: 'flex' }}>
            <LoadingSpinner />
            <strong style={{ margin: '0 14px' }}>Palun oodake, andmeid laetakse!</strong>
          </div>
        </Alert>
      )
      : (
        <div>
          <div
            id="consultationDetailView"
            style={{
              margin: '0 auto',
              width: '90%',
              minWidth: '380px',
              padding: '20px',
              borderRadius: '3px',
              border: '1px solid #ccc',
            }}
          >
            <form onSubmit={(event) => {
              event.preventDefault();
              saveChangesToServer();
            }}
            >
              <Form componentClass="div" inline>
                {
                  existingConsultation && existingConsultation.uuid
                  && (consultation.status === 'approved' || consultation.status === 'finished')
                  && (
                    <FormGroup>
                      <ControlLabel style={{ margin: '2px' }}>Avalik URL: </ControlLabel>
                      <InputGroup style={{ margin: '2px', marginRight: '6px', width: '740px' }}>
                        <FormControl
                          type="text"
                          value={publicURL}
                          disabled
                        />
                        <InputGroup.Button>
                          <Button onClick={copyPublicUrlToClipboard}>
                            {copyButtonText}
                          </Button>
                        </InputGroup.Button>
                      </InputGroup>
                    </FormGroup>
                  )
                }
                <FormGroup
                  controlId="cadastral_id"
                >
                  <ControlLabel style={{ margin: '2px' }}>Katastritunnus: </ControlLabel>
                  <FormControl
                    autoFocus
                    type="text"
                    value={consultation.cadastral_id}
                    disabled
                    style={{ margin: '2px', marginRight: '6px' }}
                  />
                  <FormControl.Feedback />
                </FormGroup>
              </Form>
              <br />
              <FormGroup
                controlId="description"
                validationState={
                  // eslint-disable-next-line no-nested-ternary
                  isDetailsEditingDisabled ? null : (consultation.description ? 'success' : 'error')
                }
              >
                <ControlLabel>Kirjeldus: </ControlLabel>
                <FormControl
                  value={consultation.description || ''}
                  onChange={handleChange}
                  componentClass="textarea"
                  style={{
                    minWidth: '100%',
                    maxWidth: '100%',
                    width: '100%',
                    minHeight: '50px',
                    height: descriptionHeight,
                  }}
                  disabled={isDetailsEditingDisabled}
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Asukoht: </ControlLabel>
                <FormControl
                  value={`${
                    consultation.local_address}, ${
                    consultation.institution}, ${
                    consultation.municipality}, ${
                    consultation.county}`}
                  type="text"
                  disabled
                />
              </FormGroup>
              <FormGroup controlId="location_description">
                <ControlLabel>Asukoha kirjeldus/märkmed: </ControlLabel>
                <FormControl
                  value={consultation.location_description || ''}
                  onChange={handleChange}
                  componentClass="textarea"
                  draggable={false}
                  style={{
                    minWidth: '100%',
                    maxWidth: '100%',
                    width: '100%',
                    minHeight: '50px',
                    height: locationDescriptionHeight,
                  }}
                  disabled={isDetailsEditingDisabled}
                />
              </FormGroup>
              <br />
              <Form componentClass="div" inline>
                <FormGroup
                  controlId="client_email"
                  validationState={
                    // eslint-disable-next-line no-nested-ternary
                    isDetailsEditingDisabled
                      ? null
                      : (emailRegex.test(consultation.client_email) ? 'success' : 'error')
                  }
                >
                  <ControlLabel style={{ margin: '2px' }}>Kliendi e-post: </ControlLabel>
                  <FormControl
                    value={consultation.client_email}
                    onChange={handleChange}
                    type="email"
                    disabled={isDetailsEditingDisabled}
                    style={{ margin: '2px', marginRight: '6px' }}
                  />
                  <FormControl.Feedback />
                </FormGroup>
                <FormGroup controlId="client_phone">
                  <ControlLabel style={{ margin: '2px' }}>Kliendi telefon: </ControlLabel>
                  <FormControl
                    value={consultation.client_phone}
                    onChange={handleChange}
                    type="text"
                    disabled={isDetailsEditingDisabled}
                    style={{ margin: '2px', marginRight: '6px' }}
                  />
                </FormGroup>
              </Form>
              <hr />
              <FormGroup controlId="buildings">
                <FormControl
                  value={JSON.stringify(consultation.buildings)}
                  componentClass="textarea"
                  style={{ display: 'none' }}
                  disabled
                />
              </FormGroup>
              <Buildings
                buildings={consultation.buildings || []}
                handleConsultationBuildingsChange={handleConsultationBuildingsChange}
                isDetailsEditingDisabled={isDetailsEditingDisabled}
                textAreaHeightMultiplier={1}
              />
              <ErrorBar resetErrorState={resetConsultationErrorState} error={requestError} />
              {isLoading && (
              <Alert bsStyle="info">
                <strong>Muudatusi salvestatakse. Palun oodake!</strong>
                {consultation.status === 'finished' && (
                  <div>
                    Toimingu teostamine võtab tavapärasest kauem,
                    kuna genereeritakse ka raporti PDF.
                  </div>
                )}
              </Alert>
              )}
              {isSuccessMessageVisible && !isLoading && isChangesSaved && (
                <Alert
                  bsStyle="success"
                  onClick={() => setIsSuccessMessageVisible(false)}
                >
                  <strong>{'Muudatused salvestatud andmebaasi. Nõustamiste nimekirja juurde naasmiseks vajutage siia:  '}</strong>
                  <Button onClick={() => history.push('/')}>Tagasi</Button>
                </Alert>
              )}
              {!isDetailsEditingDisabled && (
                <ButtonGroup style={{ width: '100%' }}>
                  <Button
                    disabled={!validateForm() || isChangesSaved}
                    type="submit"
                    style={{ width: consultation.status === 'ongoing' ? '50%' : '100%' }}
                  >
                    Salvesta andmed
                  </Button>
                  {consultation.status === 'ongoing' && (
                    <Button
                      disabled={!validateForm()}
                      type="button"
                      onClick={handleSubmitForConfirmation}
                      style={{ width: '50%' }}
                    >
                      {isChangesSaved ? 'Esita kinnitamiseks' : 'Salvesta ja esita kinnitamiseks'}
                    </Button>
                  )}
                </ButtonGroup>
              )}
              {!isDetailsEditingDisabled && !isConsultant && consultation.status === 'finished' && (
                <React.Fragment>
                  <ButtonGroup style={{ width: '100%' }}>
                    <Button
                      type="button"
                      disabled={!isChangesSaved}
                      onClick={() => confirmConsultation({ consultationId, skipEmails })}
                      style={{ width: '100%' }}
                    >
                      Kinnita konsultatsioon
                    </Button>

                  </ButtonGroup>
                  <Checkbox
                    name="skipEmails"
                    onChange={toggleSkipEmails}
                    checked={skipEmails}
                    style={{ margin: '6px' }}
                  >
                    {' '}
                    Ilma emaile saatmata
                  </Checkbox>
                </React.Fragment>
              )}
            </form>
            <Prompt
              when={!isChangesSaved}
              message="Mõned muudatused ei ole veel salvestatud. Kindel et soovite lehelt lahkuda?"
            />
          </div>
        </div>
      )
  );
};

Consultation.propTypes = {
  isConsultant: bool.isRequired,
  isLoading: bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  requestError: oneOfType([bool, object]),

  getConsultation: func.isRequired,
  updateConsultation: func.isRequired,
  confirmConsultation: func.isRequired,
};

export default connect(createStructuredSelector({
  isConsultant: selectIsConsultant,
  isLoading: selectConsultationRequestLoading,
  requestError: selectConsultationRequestError,
}), {
  getConsultation: getConsultationActions.request,
  updateConsultation: updateConsultationActions.request,
  confirmConsultation: confirmConsultationActions.request,
  resetErrorState: resetConsultationErrorState,
})(Consultation);
