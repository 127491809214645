/* eslint-disable no-alert */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { useSelector } from 'react-redux';
import api from '../../../utils/api/api';
import { selectSessionToken } from '../../Auth/redux/selectors';

const ImageUploader = ({
  maxAddition,
  addImages,
}) => {
  // TODO: https://stackoverflow.com/questions/54903511/file-upload-progress-using-react-dropzone
  // Add upload progress.
  const sessionToken = useSelector(selectSessionToken);
  const uploadImages = useCallback((formData) => {
    api(`/client/files?session_token=${sessionToken}`, {
      withoutContentType: true,
      method: 'POST',
      body: formData,
    })
      .then((result) => {
        if (result && result.data) {
          addImages(result.data);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        window.alert('Failide üles laadimine ebaõnnestus. Palun proovige uuesti.');
      });
  }, [sessionToken, addImages]);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > maxAddition) {
      const alertTextPart = maxAddition === 1 ? 'ainult üks pilt.'
        : `1 kuni ${maxAddition} pilti.`;

      return window.alert(`Liiga palju faile valitud. Palun lisage ${alertTextPart}`);
    }

    const formData = new FormData();

    acceptedFiles.forEach((file) => {
      formData.append('images[]', file);
    });

    return uploadImages(formData);
  }, [uploadImages, maxAddition]);

  return (
    <Dropzone
      onDrop={onDrop}
      accept={['.png', '.gif', '.jpeg', '.jpg']}
      maxSize={10 * 1000 * 1000}
    >
      {({ getRootProps, getInputProps }) => (
        <section className="container" style={{ width: '100%' }}>
          <div {...getRootProps({ className: 'well well-sm' })}>
            <input {...getInputProps()} />
            <p>
              Pildi lisamiseks vajuta või lohista pildid siia.
              Maksimaalselt
              {' '}
              {maxAddition}
              {' '}
              pilt(i) veel lubatud, suurusega kuni 10MB.
            </p>
          </div>
        </section>
      )}
    </Dropzone>
  );
};

ImageUploader.propTypes = {
  maxAddition: PropTypes.number.isRequired,
  addImages: PropTypes.func.isRequired,
};

export default ImageUploader;
