import {
  propOr, compose, find, propEq, defaultTo,
} from 'ramda';
import { createSelector } from 'reselect';
import { safeParseInt } from '../../../utils/constants';

const selectConsultationsState = (state) => state.consultations;

const selectConsultationRequestState = compose(
  propOr({}, 'consultationRequestState'),
  selectConsultationsState,
);

const selectConsultationRequestLoading = compose(
  propOr(false, 'loading'),
  selectConsultationRequestState,
);

const selectConsultationRequestError = compose(
  propOr(false, 'error'),
  selectConsultationRequestState,
);

const selectConsultationsRequestState = compose(
  propOr({}, 'consultationsRequestState'),
  selectConsultationsState,
);

const selectConsultationsRequestLoading = compose(
  propOr(false, 'loading'),
  selectConsultationsRequestState,
);

const selectConsultationsRequestError = compose(
  propOr(false, 'error'),
  selectConsultationsRequestState,
);

const selectConsultations = compose(
  propOr([], 'consultations'),
  selectConsultationsState,
);

const makeSelectGetConsultationById = (id) => createSelector(
  selectConsultations,
  compose(
    defaultTo({}),
    find(propEq('id', safeParseInt(id, 0))),
  ),
);

const makeSelectGetConsultationByUuid = (uuid) => createSelector(
  selectConsultations,
  compose(
    defaultTo({}),
    find(propEq('uuid', uuid)),
  ),
);

export {
  selectConsultationRequestLoading,
  selectConsultationRequestError,
  selectConsultationsRequestLoading,
  selectConsultationsRequestError,
  selectConsultations,
  makeSelectGetConsultationById,
  makeSelectGetConsultationByUuid,
};
