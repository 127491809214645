import { makeRequestActionCreators } from '../../../utils/helpers';
import {
  loginUserTypes,
  refreshSessionTypes,
  RESTORE_SESSION_SUCCESS,
  RESET_AUTH_STATE,
  RESET_REFRESH_SESSION_STATE,
  sendEmailReminderTypes,
  SHOW_REFRESH_SESSION_MODAL,
  HIDE_REFRESH_SESSION_MODAL,
} from './actionTypes';

export const loginUserActions = makeRequestActionCreators(
  loginUserTypes,
);

export const refreshSessionActions = makeRequestActionCreators(
  refreshSessionTypes,
);

export const restoreSessionSuccess = (session) => ({
  type: RESTORE_SESSION_SUCCESS,
  payload: session,
});

export const resetAuthState = () => ({
  type: RESET_AUTH_STATE,
});

export const resetRefreshSessionState = () => ({
  type: RESET_REFRESH_SESSION_STATE,
});

export const showRefreshSessionModal = () => ({
  type: SHOW_REFRESH_SESSION_MODAL,
});

export const hideRefreshSessionModal = () => ({
  type: HIDE_REFRESH_SESSION_MODAL,
});

export const sendEmailReminderActions = makeRequestActionCreators(
  sendEmailReminderTypes,
);
