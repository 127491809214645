import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import './index.css';

import React from 'react';
import { render } from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import history from './utils/history';
import configureStore from './configureStore';
import Compose from './components/Compose';

const providers = [
  [HelmetProvider],
  [ReduxProvider, { store: configureStore() }],
  [Router, { history, hashType: 'noslash' }],
];

render(
  <Compose components={providers}>
    <App />
  </Compose>,
  document.getElementById('root'),
);
