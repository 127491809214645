/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import {
  getDefaultErrorState,
  getDefaultLoadingState,
  getDefaultRequestState,
  handleActions, parseEntityArrayChanges,
} from '../../../utils/helpers';
import {
  getConsultantsTypes,
  addConsultantTypes,
  updateConsultantTypes,
  deleteConsultantTypes,
  resendConsultantCreationEmailTypes,
  getUsersTypes,
  getUserSelfTypes,
  addUserTypes,
  updateUserTypes,
  deleteUserTypes,
  resendUserCreationEmailTypes,
} from './actionTypes';

const makeInitialState = () => ({
  consultantsRequestState: getDefaultRequestState(),
  consultants: [],
  consultantRequestState: getDefaultRequestState(),
  usersRequestState: getDefaultRequestState(),
  users: [],
  userRequestState: getDefaultRequestState(),
  userSelf: {},
});

const handleConsultantsRequest = produce((draft) => {
  draft.consultantsRequestState = getDefaultLoadingState();
});

const handleConsultantsError = produce((draft, { error }) => {
  draft.consultantsRequestState = getDefaultErrorState(error);
});

const handleGetConsultants = produce((draft, { payload: consultants }) => {
  draft.consultantsRequestState = getDefaultRequestState();
  draft.consultants = consultants;
});

const handleConsultantRequest = produce((draft) => {
  draft.consultantRequestState = getDefaultLoadingState();
});

const handleConsultantError = produce((draft, { error }) => {
  draft.consultantRequestState = getDefaultErrorState(error);
});

const handleConsultantChange = produce((draft, { payload: consultant }) => {
  draft.consultantRequestState = getDefaultRequestState();
  draft.consultants = parseEntityArrayChanges(draft.consultants, consultant);
});

const handleConsultantDeletion = produce((draft, { payload: consultantId }) => {
  draft.consultantRequestState = getDefaultRequestState();
  draft.consultants = draft.consultants.filter((c) => c.id !== consultantId);
});

const resetConsultantRequestState = produce((draft) => {
  draft.consultantRequestState = getDefaultRequestState();
});

const handleUsersRequest = produce((draft) => {
  draft.usersRequestState = getDefaultLoadingState();
});

const handleUsersError = produce((draft, { error }) => {
  draft.usersRequestState = getDefaultErrorState(error);
});

const handleGetUsers = produce((draft, { payload: users }) => {
  draft.usersRequestState = getDefaultRequestState();
  draft.users = users;
});

const handleUserRequest = produce((draft) => {
  draft.userRequestState = getDefaultLoadingState();
});

const handleUserError = produce((draft, { error }) => {
  draft.userRequestState = getDefaultErrorState(error);
});

const handleGetUserSelf = produce((draft, { payload: user }) => {
  draft.usersRequestState = getDefaultRequestState();
  draft.userSelf = user;
});

const handleUserChange = produce((draft, { payload: user }) => {
  draft.userRequestState = getDefaultRequestState();
  draft.users = parseEntityArrayChanges(draft.users, user);
});

const handleUserDeletion = produce((draft, { payload: userId }) => {
  draft.userRequestState = getDefaultRequestState();
  draft.users = draft.users.filter((c) => c.id !== userId);
});

const resetUserRequestState = produce((draft) => {
  draft.userRequestState = getDefaultRequestState();
});

const typeMap = {
  [getConsultantsTypes.request]: handleConsultantsRequest,
  [getConsultantsTypes.error]: handleConsultantsError,
  [getConsultantsTypes.success]: handleGetConsultants,

  [addConsultantTypes.request]: handleConsultantRequest,
  [addConsultantTypes.error]: handleConsultantError,
  [addConsultantTypes.success]: handleConsultantChange,

  [updateConsultantTypes.request]: handleConsultantRequest,
  [updateConsultantTypes.error]: handleConsultantError,
  [updateConsultantTypes.success]: handleConsultantChange,

  [deleteConsultantTypes.request]: handleConsultantRequest,
  [deleteConsultantTypes.error]: handleConsultantError,
  [deleteConsultantTypes.success]: handleConsultantDeletion,

  [resendConsultantCreationEmailTypes.request]: handleConsultantRequest,
  [resendConsultantCreationEmailTypes.error]: handleConsultantError,
  [resendConsultantCreationEmailTypes.success]: resetConsultantRequestState,

  [getUsersTypes.request]: handleUsersRequest,
  [getUsersTypes.error]: handleUsersError,
  [getUsersTypes.success]: handleGetUsers,

  [getUserSelfTypes.request]: handleUsersRequest,
  [getUserSelfTypes.error]: handleUsersError,
  [getUserSelfTypes.success]: handleGetUserSelf,

  [addUserTypes.request]: handleUserRequest,
  [addUserTypes.error]: handleUserError,
  [addUserTypes.success]: handleUserChange,

  [updateUserTypes.request]: handleUserRequest,
  [updateUserTypes.error]: handleUserError,
  [updateUserTypes.success]: handleUserChange,

  [deleteUserTypes.request]: handleUserRequest,
  [deleteUserTypes.error]: handleUserError,
  [deleteUserTypes.success]: handleUserDeletion,

  [resendUserCreationEmailTypes.request]: handleUserRequest,
  [resendUserCreationEmailTypes.error]: handleUserError,
  [resendUserCreationEmailTypes.success]: resetUserRequestState,
};

export default function usersReducer(state = makeInitialState(), action) {
  return handleActions(typeMap)(state, action);
}
