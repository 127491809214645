/* eslint-disable no-alert,consistent-return */
import {
  Button, ButtonGroup, ButtonToolbar, Glyphicon,
} from 'react-bootstrap';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ToolTip from '../../../../utils/ToolTip';
import {
  confirmConsultationActions,
  deleteConsultationActions,
  disproveConsultationActions,
  rejectConsultationActions,
  resetConsultationActions,
} from '../../redux/actions';
import { selectIsConsultant } from '../../../Auth/redux/selectors';

const ConsultationControlButtons = ({
  consultation,
}) => {
  const history = useHistory();
  const isConsultant = useSelector(selectIsConsultant);

  return (
    <ButtonToolbar>
      <ButtonGroup bsSize="small">
        {ToolTip(
          'button-details',
          <Button onClick={() => history.push(`/consultations/${consultation.id}`)}>
            <Glyphicon glyph="glyphicon glyphicon-edit" />
          </Button>,
        )}
        <ConfirmActionButton
          consultationId={consultation.id}
          consultationStatus={consultation.status}
          isConsultant={isConsultant}
        />
        {!isConsultant && (
        <DisproveActionButton
          consultationId={consultation.id}
          consultationStatus={consultation.status}
        />
        )}
      </ButtonGroup>
      {!isConsultant && (
      <Fragment>
        <ButtonGroup bsSize="small">
          <RejectActionButton
            consultationId={consultation.id}
            consultationStatus={consultation.status}
          />
          <DeleteActionButton
            consultationId={consultation.id}
            consultationStatus={consultation.status}
          />
          <ResetActionButton
            consultationId={consultation.id}
            consultationStatus={consultation.status}
          />
        </ButtonGroup>
      </Fragment>
      )}
    </ButtonToolbar>
  );
};

ConsultationControlButtons.propTypes = {
  consultation: PropTypes.shape({}).isRequired,
};

const ResetActionButton = ({ consultationId, consultationStatus }) => {
  const dispatch = useDispatch();

  if (consultationStatus === 'applied') {
    return (
      <Button disabled>
        <Glyphicon glyph="glyphicon glyphicon-retweet" />
      </Button>
    );
  }

  return ToolTip(
    'button-reset',
    <Button onClick={() => {
      if (window.confirm('Kindel et soovite nõustamist lähtestada?\n'
                + 'Antud tegevus:\n'
                + '* kustutab konsultandi poolt sisestatud andmed (ehitised, kahjustused ja pildid)s\n'
                + '* eemaldab konsultandi nõustamisest\n'
                + '* seab konsultatsiooni staatuseks "Esitatud".')) {
        return dispatch(resetConsultationActions.request(consultationId));
      }
    }}
    >
      <Glyphicon glyph="glyphicon glyphicon-retweet" />
    </Button>,
  );
};

ResetActionButton.propTypes = {
  consultationId: PropTypes.number.isRequired,
  consultationStatus: PropTypes.string.isRequired,
};

const DeleteActionButton = ({ consultationId, consultationStatus }) => {
  const dispatch = useDispatch();

  if (consultationStatus !== 'rejected' && consultationStatus !== 'approved') {
    return (
      <Button disabled>
        <Glyphicon glyph="glyphicon glyphicon-trash" />
      </Button>
    );
  }

  return ToolTip(
    'button-delete',
    <Button onClick={() => {
      if (window.confirm('Kindel et soovite nõustamist kustutada?')) {
        return dispatch(deleteConsultationActions.request(consultationId));
      }
    }}
    >
      <Glyphicon glyph="glyphicon glyphicon-trash" />
    </Button>,
  );
};

DeleteActionButton.propTypes = {
  consultationId: PropTypes.number.isRequired,
  consultationStatus: PropTypes.string.isRequired,
};

const ConfirmActionButton = ({ consultationId, consultationStatus, isConsultant }) => {
  const dispatch = useDispatch();
  const hideConfirmButton = (consultationStatus === 'finished' && isConsultant)
        || (consultationStatus !== 'pending' && consultationStatus !== 'ongoing' && consultationStatus !== 'finished');

  if (hideConfirmButton) {
    return (
      <Button disabled>
        <Glyphicon glyph="glyphicon glyphicon-ok-sign" />
      </Button>
    );
  }

  return ToolTip(
    'button-approveAction',
    <Button onClick={() => dispatch(confirmConsultationActions.request({ consultationId }))}>
      <Glyphicon glyph="glyphicon glyphicon-ok-sign" />
    </Button>,
  );
};

ConfirmActionButton.propTypes = {
  consultationId: PropTypes.number.isRequired,
  consultationStatus: PropTypes.string.isRequired,
  isConsultant: PropTypes.bool.isRequired,
};

const DisproveActionButton = ({ consultationId, consultationStatus }) => {
  const dispatch = useDispatch();

  if (consultationStatus !== 'finished' && consultationStatus !== 'approved') {
    return (
      <Button disabled>
        <Glyphicon glyph="glyphicon glyphicon-remove-sign" />
      </Button>
    );
  }

  return ToolTip(
    'button-disproveAction',
    <Button onClick={() => dispatch(disproveConsultationActions.request(consultationId))}>
      <Glyphicon glyph="glyphicon glyphicon-remove-sign" />
    </Button>,
  );
};

DisproveActionButton.propTypes = {
  consultationId: PropTypes.number.isRequired,
  consultationStatus: PropTypes.string.isRequired,
};

const RejectActionButton = ({ consultationId, consultationStatus }) => {
  const dispatch = useDispatch();

  if (consultationStatus === 'rejected' || consultationStatus === 'finished' || consultationStatus === 'approved') {
    return (
      <Button disabled>
        <Glyphicon glyph="glyphicon glyphicon-minus-sign" />
      </Button>
    );
  }

  return ToolTip(
    'button-reject',
    <Button onClick={() => {
      if (window.confirm('Kindel et soovite nõustamisest loobuda ning selle arhiveerida?')) {
        return dispatch(rejectConsultationActions.request(consultationId));
      }
    }}
    >
      <Glyphicon glyph="glyphicon glyphicon-minus-sign" />
    </Button>,
  );
};

RejectActionButton.propTypes = {
  consultationId: PropTypes.number.isRequired,
  consultationStatus: PropTypes.string.isRequired,
};

export default ConsultationControlButtons;
