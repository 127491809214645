import { call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import {
  loginUserTypes,
  LOGOUT_USER,
  refreshSessionTypes,
  sendEmailReminderTypes,
} from './actionTypes';
import { api, sessionApi } from '../../../utils/api/sagaApi';
import {
  loginUserActions,
  refreshSessionActions,
  restoreSessionSuccess,
  sendEmailReminderActions,
} from './actions';
import { RESET_STORE } from '../../../actionTypes';

function storeSessionToLocalStorage(session = {}) {
  const sessionString = JSON.stringify(session);

  window.localStorage.setItem('session', sessionString);
}

function loadSessionFromLocalStorage() {
  const sessionString = window.localStorage.getItem('session');

  let session;

  try {
    session = JSON.parse(sessionString);
  } catch (e) {
    session = {};
  }

  return session;
}

function* loginUser({ payload: { email, secret } }) {
  try {
    const data = yield call(api, {
      path: '/public/auth/login/',
      method: 'POST',
      body: { email, secret },
    });

    const session = {
      isAdmin: data.is_admin,
      isConsultant: data.is_consultant,
      sessionToken: data.session_token,
      userId: data.user_id,
      sessionValidTill: data.valid_till,
    };

    yield put(loginUserActions.success(session));
    yield call(storeSessionToLocalStorage, session);
  } catch (error) {
    yield put(loginUserActions.error(error));
  }
}

function* refreshSessionToken({ payload: { email, secret } }) {
  try {
    const data = yield call(api, {
      path: '/public/auth/login/',
      method: 'POST',
      body: { email, secret },
    });

    const session = {
      isAdmin: data.is_admin,
      isConsultant: data.is_consultant,
      sessionToken: data.session_token,
      userId: data.user_id,
      sessionValidTill: data.valid_till,
    };

    yield put(refreshSessionActions.success(session));
    yield call(storeSessionToLocalStorage, session);
  } catch (error) {
    yield put(refreshSessionActions.error(error));
  }
}

function* logoutUser() {
  try {
    yield call(sessionApi, {
      path: '/public/auth/logout',
      method: 'POST',
    });
    yield call(storeSessionToLocalStorage);
    yield put({
      type: RESET_STORE,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
}

function* restoreSession() {
  try {
    const session = yield call(loadSessionFromLocalStorage);

    if (!session || !session.sessionToken) {
      return;
    }

    const sessionIsValid = moment(session.sessionValidTill).isAfter(moment());

    if (sessionIsValid) {
      yield put(restoreSessionSuccess(session));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
}

function* sendEmailRemainder({ payload: email }) {
  try {
    yield call(api, {
      path: '/public/auth/remind',
      method: 'POST',
      body: { email },
    });

    yield put(sendEmailReminderActions.success());
  } catch (error) {
    error.reminderSentFailed = true;
    yield put(sendEmailReminderActions.error(error));
  }
}

export default [
  takeLatest(loginUserTypes.request, loginUser),
  takeLatest(refreshSessionTypes.request, refreshSessionToken),
  takeLatest(LOGOUT_USER, logoutUser),
  takeLatest(sendEmailReminderTypes.request, sendEmailRemainder),
  call(restoreSession),
];
