/* eslint-disable no-alert,consistent-return */
import { Button, ButtonGroup, Glyphicon } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import ToolTip from '../../../../utils/ToolTip';

const UsersControlButtons = ({
  deleteFunction,
  resendAccountCreationEmailFunction,
  userId,
  userIsAdmin,
  emailIsConfirmed,
}) => {
  const disabledDeleteUserButton = (
    <Button disabled>
      <Glyphicon glyph="glyphicon glyphicon-trash" />
    </Button>
  );
  const disabledEmailResendButton = (
    <Button disabled>
      <Glyphicon glyph="glyphicon glyphicon-envelope" />
    </Button>
  );

  return (
    <ButtonGroup bsSize="small">
      {userIsAdmin ? disabledDeleteUserButton : ToolTip(
        'button-delete-user',
        <Button
          onClick={() => {
            if (window.confirm('Kindel et soovite kasutajat/konsulteerijat kustutada? \n'
                    + 'Antud toiming eemaldab ligipääsu nõustamissüsteemi.\n'
                    + 'Viited nõustamistele säilivad.')) {
              return deleteFunction(userId);
            }
          }}
        >
          <Glyphicon glyph="glyphicon glyphicon-trash" />
        </Button>,
      )}
      {emailIsConfirmed ? disabledEmailResendButton : ToolTip(
        'button-resend-confirmation',
        <Button
          onClick={() => {
            if (window.confirm('Saadan konto aktiveerimise kiri uuesti.')) {
              return resendAccountCreationEmailFunction(userId);
            }
          }}
        >
          <Glyphicon glyph="glyphicon glyphicon-envelope" />
        </Button>,
      )}
    </ButtonGroup>
  );
};

UsersControlButtons.propTypes = {
  deleteFunction: PropTypes.func.isRequired,
  resendAccountCreationEmailFunction: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  userIsAdmin: PropTypes.bool.isRequired,
  emailIsConfirmed: PropTypes.bool.isRequired,
};

export default UsersControlButtons;
