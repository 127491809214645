import { propOr, compose } from 'ramda';

const selectAuth = (state) => state.auth;

const authRequestState = compose(
  propOr({}, 'authRequest'),
  selectAuth,
);

const selectLoading = compose(
  propOr(false, 'loading'),
  authRequestState,
);

const selectError = compose(
  propOr(false, 'error'),
  authRequestState,
);
const selectShowRefreshSessionModal = compose(
  propOr(false, 'showRefreshSessionModal'),
  selectAuth,
);

const refreshSessionRequestState = compose(
  propOr({}, 'refreshSessionRequest'),
  selectAuth,
);

const selectRefreshSessionLoading = compose(
  propOr(false, 'loading'),
  refreshSessionRequestState,
);

const selectRefreshSessionError = compose(
  propOr(false, 'error'),
  refreshSessionRequestState,
);

// Session
const selectSession = compose(
  propOr({}, 'session'),
  selectAuth,
);

const selectSessionToken = compose(
  propOr('', 'sessionToken'),
  selectSession,
);

const selectIsUserLoggedIn = compose(
  Boolean,
  selectSessionToken,
);

const selectIsAdmin = compose(
  Boolean,
  propOr(false, 'isAdmin'),
  selectSession,
);

const selectIsConsultant = compose(
  Boolean,
  propOr(true, 'isConsultant'),
  selectSession,
);

const selectSessionUserId = compose(
  propOr('', 'userId'),
  selectSession,
);

export {
  selectLoading,
  selectError,
  selectSession,
  selectSessionToken,
  selectIsUserLoggedIn,
  selectIsAdmin,
  selectIsConsultant,
  selectSessionUserId,
  selectRefreshSessionLoading,
  selectRefreshSessionError,
  selectShowRefreshSessionModal,
};
