import { combineReducers } from 'redux';
import authReducer from './modules/Auth/redux/authReducer';
import consultationsReducer from './modules/Consultations/redux/consultationsReducer';
import usersReducer from './modules/Users/redux/usersReducer';
import { RESET_STORE } from './actionTypes';

const createRootReducer = () => {
  const rootReducer = combineReducers({
    auth: authReducer,
    consultations: consultationsReducer,
    users: usersReducer,
  });

  return (state, action) => {
    if (action.type === RESET_STORE) {
      // eslint-disable-next-line no-undefined
      return rootReducer(undefined, action);
    }

    return rootReducer(state, action);
  };
};

export default createRootReducer;
