/* eslint-disable no-alert */
import React, { useEffect, useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import cellEditFactory from 'react-bootstrap-table2-editor';
import PropTypes from 'prop-types';

import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectConsultants, selectUsers } from '../redux/selectors';
import {
  deleteConsultantActions,
  deleteUserActions,
  getConsultantsActions,
  getUsersActions,
  resendConsultantCreationEmailActions,
  resendUserCreationEmailActions,
  updateConsultantActions,
  updateUserActions,
} from '../redux/actions';
import { getTableHeaderData } from '../../../utils/tableHeaderData';

const { SearchBar } = Search;

const Users = ({
  isConsultantView,
  data,

  getData,
  updateFunction,
  deleteFunction,
  resendAccountCreationEmailFunction,
}) => {
  const history = useHistory();

  const tableHeaderData = useMemo(
    () => getTableHeaderData(isConsultantView, deleteFunction, resendAccountCreationEmailFunction),
    [isConsultantView, deleteFunction, resendAccountCreationEmailFunction],
  );

  useEffect(() => {
    getData();
  }, [getData]);

  const cellEditOptions = cellEditFactory({
    mode: 'dbclick',
    blurToSave: true,
    beforeSaveCell(oldValue, newValue, row, column, done) {
      setTimeout(() => {
        if (oldValue === newValue) {
          return done(false);
        }

        if (window.confirm(`Kinnita muutus: \n${column.text}: ${
          oldValue || '(puudub)'} => ${newValue}`)) {
          const updateRequest = {
            id: row.id,
          };

          updateRequest[column.dataField] = newValue;
          updateFunction(updateRequest);
        }

        return done(false);
      }, 0);

      return { async: true };
    },
  });

  return (
    <div>
      <ToolkitProvider
        keyField="id"
        data={data}
        columns={tableHeaderData}
        search
      >
        {
          (tkProps) => (
            <div>
              <SearchBar
                {...tkProps.searchProps}
                style={{ display: 'inline', verticalAlign: 'middle', margin: '2px' }}
                delay={1000}
                placeholder="Otsing"
              />
              <Button
                style={{ margin: '2px', float: 'right' }}
                onClick={() => history.push(`/${isConsultantView ? 'consultants' : 'users'}/new`)}
              >
                Lisa uus
                {' '}
                {isConsultantView ? 'konsultant' : 'kasutaja'}
              </Button>
              <hr />
              <BootstrapTable
                {...tkProps.baseProps}
                condensed
                rowStyle={{ wordWrap: 'break-word' }}
                noDataIndication="Table on tühi!"
                cellEdit={cellEditOptions}
              />
            </div>
          )
        }
      </ToolkitProvider>
    </div>
  );
};

Users.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isConsultantView: PropTypes.bool.isRequired,

  getData: PropTypes.func.isRequired,
  updateFunction: PropTypes.func.isRequired,
  deleteFunction: PropTypes.func.isRequired,
  resendAccountCreationEmailFunction: PropTypes.func.isRequired,
};

const UserWrapper = connect(createStructuredSelector({
  consultants: selectConsultants,
  users: selectUsers,
}), {
  updateConsultant: updateConsultantActions.request,
  deleteConsultant: deleteConsultantActions.request,
  updateUser: updateUserActions.request,
  deleteUser: deleteUserActions.request,
  resendUserAccountCreationEmail: resendUserCreationEmailActions.request,
  resendConsultantAccountCreationEmail: resendConsultantCreationEmailActions.request,
  getConsultants: getConsultantsActions.request,
  getUsers: getUsersActions.request,
}, (stateProps, dispatchProps, ownProps) => {
  const isConsultantView = ownProps.type === 'consultant';
  const data = isConsultantView ? stateProps.consultants : stateProps.users;

  const getData = isConsultantView ? dispatchProps.getConsultants : dispatchProps.getUsers;
  const updateFunction = isConsultantView
    ? dispatchProps.updateConsultant : dispatchProps.updateUser;
  const deleteFunction = isConsultantView
    ? dispatchProps.deleteConsultant : dispatchProps.deleteUser;
  const resendAccountCreationEmailFunction = isConsultantView
    ? dispatchProps.resendConsultantAccountCreationEmail
    : dispatchProps.resendUserAccountCreationEmail;

  return {
    isConsultantView,
    data,

    getData,
    updateFunction,
    deleteFunction,
    resendAccountCreationEmailFunction,
  };
})(Users);

UserWrapper.propTypes = {
  type: PropTypes.oneOf(['user', 'consultant']),
};

export default UserWrapper;
