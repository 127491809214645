/* eslint-disable no-alert */
import { Button, ButtonGroup, Glyphicon } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import ToolTip from '../../../../utils/ToolTip';
import { removeConsultantFromConsultationActions } from '../../redux/actions';

const ConsultationConsultantControlButton = ({
  consultation,
  handleShowConsultantSelectionModal,
}) => (
  <div>
    {consultation.consultant_name}
    <ButtonGroup bsSize="xsmall" vertical style={{ display: 'inline-block', float: 'right' }}>
      <ConsultantActionButton
        consultationId={consultation.id}
        consultantId={consultation.consultant_id}
        consultationStatus={consultation.status}
        handleShowConsultantSelectionModal={handleShowConsultantSelectionModal}
      />
    </ButtonGroup>
  </div>
);

ConsultationConsultantControlButton.propTypes = {
  consultation: PropTypes.shape({}).isRequired,
  handleShowConsultantSelectionModal: PropTypes.func.isRequired,
};

const ConsultantActionButton = ({
  consultationId,
  consultationStatus,
  consultantId,
  handleShowConsultantSelectionModal,
}) => {
  const dispatch = useDispatch();

  if (consultationStatus === 'applied') {
    return ToolTip(
      'button-addConsultant',
      <Button onClick={() => handleShowConsultantSelectionModal(consultationId)}>
        <Glyphicon glyph="glyphicon glyphicon-plus" />
      </Button>,
    );
  }

  if (consultantId && consultationStatus !== 'rejected' && consultationStatus !== 'approved') {
    return ToolTip(
      'button-removeConsultant',
      // eslint-disable-next-line consistent-return
      <Button onClick={() => {
        if (window.confirm('Kindel et soovite konsulteerijat antud nõustamisest eemaldada?')) {
          return dispatch(removeConsultantFromConsultationActions.request(consultationId));
        }
      }}
      >
        <Glyphicon glyph="glyphicon glyphicon-minus" />
      </Button>,
    );
  }

  return null;
};

ConsultantActionButton.protoTypes = {
  consultationId: PropTypes.number.isRequired,
  consultantId: PropTypes.number,
  consultationStatus: PropTypes.string.isRequired,
  handleShowConsultantSelectionModal: PropTypes.func.isRequired,
};

export default ConsultationConsultantControlButton;
