/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import {
  getDefaultErrorState,
  getDefaultLoadingState,
  getDefaultRequestState,
  handleActions,
  parseEntityArrayChanges,
} from '../../../utils/helpers';
import {
  getConsultationsTypes,
  getConsultationTypes,
  getPublicConsultationTypes,
  deleteConsultationTypes,
  updateConsultationTypes,
  createConsultationTypes,
  confirmConsultationTypes,
  rejectConsultationTypes,
  disproveConsultationTypes,
  resetConsultationTypes,
  removeConsultantFromConsultationTypes,
  addConsultantToConsultationTypes,
  RESET_CONSULTATION_ERROR_STATE,
  RESET_CONSULTATIONS_ERROR_STATE,
} from './actionTypes';

const makeInitialState = () => ({
  consultationRequestState: getDefaultRequestState(),
  consultationsRequestState: getDefaultRequestState(),
  consultations: [],
});

const handleConsultationsRequest = produce((draft) => {
  draft.consultationsRequestState = getDefaultLoadingState();
});

const handleConsultationsError = produce((draft, { error }) => {
  draft.consultationsRequestState = getDefaultErrorState(error);
});

const handleGetConsultations = produce((draft, { payload: consultations }) => {
  draft.consultationsRequestState = getDefaultRequestState();
  draft.consultations = consultations;
});

const handleConsultationRequest = produce((draft) => {
  draft.consultationRequestState = getDefaultLoadingState();
});

const handleConsultationError = produce((draft, { error }) => {
  draft.consultationRequestState = getDefaultErrorState(error);
});

const handleConsultationChange = produce((draft, { payload: consultation }) => {
  draft.consultationRequestState = getDefaultRequestState();
  draft.consultations = parseEntityArrayChanges(draft.consultations, consultation);
});

const handleConsultationDeletion = produce((draft, { payload: consultationId }) => {
  draft.consultationRequestState = getDefaultRequestState();
  draft.consultations = draft.consultations.filter((c) => c.id !== consultationId);
});

const resetConsultationErrorState = produce((draft) => {
  draft.consultationRequestState = getDefaultRequestState();
});

const resetConsultationsErrorState = produce((draft) => {
  draft.consultationsRequestState = getDefaultRequestState();
});

const typeMap = {
  [getConsultationsTypes.request]: handleConsultationsRequest,
  [getConsultationsTypes.error]: handleConsultationsError,
  [getConsultationsTypes.success]: handleGetConsultations,

  [getConsultationTypes.request]: handleConsultationRequest,
  [getConsultationTypes.error]: handleConsultationError,
  [getConsultationTypes.success]: handleConsultationChange,

  [getPublicConsultationTypes.request]: handleConsultationRequest,
  [getPublicConsultationTypes.error]: handleConsultationError,
  [getPublicConsultationTypes.success]: handleConsultationChange,

  [deleteConsultationTypes.request]: handleConsultationRequest,
  [deleteConsultationTypes.error]: handleConsultationError,
  [deleteConsultationTypes.success]: handleConsultationDeletion,

  [updateConsultationTypes.request]: handleConsultationRequest,
  [updateConsultationTypes.error]: handleConsultationError,
  [updateConsultationTypes.success]: handleConsultationChange,

  [createConsultationTypes.request]: handleConsultationRequest,
  [createConsultationTypes.error]: handleConsultationError,
  [createConsultationTypes.success]: handleConsultationChange,

  [confirmConsultationTypes.request]: handleConsultationRequest,
  [confirmConsultationTypes.error]: handleConsultationError,
  [confirmConsultationTypes.success]: handleConsultationChange,

  [rejectConsultationTypes.request]: handleConsultationRequest,
  [rejectConsultationTypes.error]: handleConsultationError,
  [rejectConsultationTypes.success]: handleConsultationChange,

  [disproveConsultationTypes.request]: handleConsultationRequest,
  [disproveConsultationTypes.error]: handleConsultationError,
  [disproveConsultationTypes.success]: handleConsultationChange,

  [resetConsultationTypes.request]: handleConsultationRequest,
  [resetConsultationTypes.error]: handleConsultationError,
  [resetConsultationTypes.success]: handleConsultationChange,

  [removeConsultantFromConsultationTypes.request]: handleConsultationRequest,
  [removeConsultantFromConsultationTypes.error]: handleConsultationError,
  [removeConsultantFromConsultationTypes.success]: handleConsultationChange,

  [addConsultantToConsultationTypes.request]: handleConsultationRequest,
  [addConsultantToConsultationTypes.error]: handleConsultationError,
  [addConsultantToConsultationTypes.success]: handleConsultationChange,

  [RESET_CONSULTATION_ERROR_STATE]: resetConsultationErrorState,
  [RESET_CONSULTATIONS_ERROR_STATE]: resetConsultationsErrorState,
};

export default function consultationsReducer(state = makeInitialState(), action) {
  return handleActions(typeMap)(state, action);
}
