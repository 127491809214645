import { colors, statusDefinitions } from './constants';

export const formatConsultationStatus = (statusKey) => {
  const statusesMap = {
    applied: {
      tooltip: statusDefinitions.applied,
      color: colors.Orange,
      icon: 'glyphicon glyphicon-warning-sign',
      text: 'Esitatud',
    },
    rejected: {
      tooltip: statusDefinitions.rejected,
      color: colors.Red,
      icon: 'glyphicon glyphicon-exclamation-sign',
      text: 'Keeldutud',
    },
    pending: {
      tooltip: statusDefinitions.pending,
      color: colors.Deepskyblue,
      icon: 'glyphicon glyphicon-time',
      text: 'Pakkumisel',
    },
    ongoing: {
      tooltip: statusDefinitions.ongoing,
      color: colors.Chartreuse,
      icon: 'glyphicon glyphicon-time',
      text: 'Töös',
    },
    finished: {
      tooltip: statusDefinitions.finished,
      color: colors.Chartreuse,
      icon: 'glyphicon glyphicon-unchecked',
      text: 'Lõpetatud',
    },
    approved: {
      tooltip: statusDefinitions.approved,
      color: colors.Green,
      icon: 'glyphicon glyphicon-check',
      text: 'Kinnitatud',
    },
  };

  return statusesMap[statusKey];
};

export const cleanLineEndindgs = (description) => description && description.replace(/[\r\n]+/gm, ' ');
