import React from 'react';
import Spinner from 'react-loader-spinner';
import { string } from 'prop-types';

const LoadingSpinner = ({
  size = '24',
  type = 'Watch',
}) => (
  <Spinner
    type={type}
    color="#00BFFF"
    height={size}
    width={size}
  />
);

LoadingSpinner.propTypes = {
  size: string,
  type: string,
};

LoadingSpinner.defaultProps = {
  size: '24',
  type: 'Watch',
};

export default LoadingSpinner;
