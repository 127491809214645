import {
  call, put, takeLatest, select,
} from 'redux-saga/effects';
import { sessionApi } from '../../../utils/api/sagaApi';
import {
  getConsultantsActions,
  addConsultantActions,
  updateConsultantActions,
  deleteConsultantActions,
  resendConsultantCreationEmailActions,
  getUsersActions,
  getUserSelfActions,
  addUserActions,
  updateUserActions,
  deleteUserActions,
  resendUserCreationEmailActions,
} from './actions';
import {
  getConsultantsTypes,
  addConsultantTypes,
  updateConsultantTypes,
  deleteConsultantTypes,
  resendConsultantCreationEmailTypes,
  getUsersTypes,
  getUserSelfTypes,
  addUserTypes,
  updateUserTypes,
  deleteUserTypes,
  resendUserCreationEmailTypes,
} from './actionTypes';
import { selectIsAdmin, selectIsConsultant, selectSessionUserId } from '../../Auth/redux/selectors';

function* getConsultants() {
  const isConsultant = yield select(selectIsConsultant);

  if (isConsultant) {
    return;
  }

  try {
    const data = yield call(sessionApi, {
      path: '/client/consultants',
    });

    yield put(getConsultantsActions.success(data));
  } catch (error) {
    yield put(getConsultantsActions.error(error));
  }
}

function* addConsultant({ payload: consultant }) {
  const isConsultant = yield select(selectIsConsultant);

  if (isConsultant) {
    return;
  }

  try {
    const data = yield call(sessionApi, {
      path: '/client/consultants',
      method: 'POST',
      body: {
        account_type: 'consultant',
        name: consultant.name,
        email: consultant.email,
        phone: consultant.phone,
        counties: consultant.counties,
        description: consultant.description,
        company_name: consultant.company_name,
      },
    });

    yield put(addConsultantActions.success(data));
  } catch (error) {
    yield put(addConsultantActions.error(error));
  }
}

function* updateConsultant({ payload: consultant }) {
  const isConsultant = yield select(selectIsConsultant);

  if (isConsultant) {
    return;
  }

  try {
    const data = yield call(sessionApi, {
      path: `/client/consultants/${consultant.id}`,
      method: 'PUT',
      body: consultant,
    });

    yield put(updateConsultantActions.success(data));
  } catch (error) {
    yield put(updateConsultantActions.error(error));
  }
}

function* deleteConsultant({ payload: consultantId }) {
  const isConsultant = yield select(selectIsConsultant);

  if (isConsultant) {
    return;
  }

  try {
    yield call(sessionApi, {
      path: `/client/consultants/${consultantId}`,
      method: 'DELETE',
    });

    yield put(deleteConsultantActions.success(consultantId));
  } catch (error) {
    yield put(deleteConsultantActions.error(error));
  }
}

function* resendConsultantAccountCreationEmail({ payload: consultantId }) {
  const isConsultant = yield select(selectIsConsultant);

  if (isConsultant) {
    return;
  }

  try {
    const data = yield call(sessionApi, {
      path: `/client/consultants/${consultantId}/resendAccountCreationEmail`,
      method: 'POST',
    });

    yield put(resendConsultantCreationEmailActions.success(data));
  } catch (error) {
    yield put(resendConsultantCreationEmailActions.error(error));
  }
}

function* getUsers() {
  const isAdmin = yield select(selectIsAdmin);

  if (!isAdmin) {
    return;
  }

  try {
    const data = yield call(sessionApi, {
      path: '/client/users',
    });

    yield put(getUsersActions.success(data));
  } catch (error) {
    yield put(getUsersActions.error(error));
  }
}

function* getUserSelf() {
  const userId = yield select(selectSessionUserId);

  if (!userId) {
    return;
  }

  try {
    const data = yield call(sessionApi, {
      path: `/client/users/${userId}`,
    });

    yield put(getUserSelfActions.success(data));
  } catch (error) {
    yield put(getUserSelfActions.error(error));
  }
}

function* addUser({ payload: user }) {
  const isAdmin = yield select(selectIsAdmin);

  if (!isAdmin) {
    return;
  }

  try {
    const data = yield call(sessionApi, {
      path: '/client/users',
      method: 'POST',
      body: {
        account_type: user.accountType || 'user',
        name: user.name,
        email: user.email,
        phone: user.phone,
        counties: user.counties,
        description: user.description,
        company_name: user.company_name,
      },
    });

    yield put(addUserActions.success(data));
  } catch (error) {
    yield put(addUserActions.error(error));
  }
}

function* updateUser({ payload: user }) {
  const isAdmin = yield select(selectIsAdmin);
  const userId = yield select(selectSessionUserId);
  const isUpdatingSelf = user.id === userId;

  if (!isUpdatingSelf && !isAdmin) {
    return;
  }

  try {
    const data = yield call(sessionApi, {
      path: `/client/users/${user.id}`,
      method: 'PUT',
      body: user,
    });

    yield put(updateUserActions.success(data, { isUpdatingSelf }));
  } catch (error) {
    yield put(updateUserActions.error(error));
  }
}

function* deleteUser({ payload: userId }) {
  const isAdmin = yield select(selectIsAdmin);
  const userSelfId = yield select(selectSessionUserId);
  const isDeletingSelf = userId === userSelfId;

  if (!isDeletingSelf && !isAdmin) {
    return;
  }

  try {
    const data = yield call(sessionApi, {
      path: `/client/users/${userId}`,
      method: 'DELETE',
    });

    yield put(deleteUserActions.success(data));
  } catch (error) {
    yield put(deleteUserActions.error(error));
  }
}

function* resendUserAccountCreationEmail({ payload: userId }) {
  const isAdmin = yield select(selectIsAdmin);

  if (!isAdmin) {
    return;
  }

  try {
    const data = yield call(sessionApi, {
      path: `/client/users/${userId}/resendAccountCreationEmail`,
      method: 'POST',
    });

    yield put(resendUserCreationEmailActions.success(data));
  } catch (error) {
    yield put(resendUserCreationEmailActions.error(error));
  }
}

export default [
  takeLatest(getConsultantsTypes.request, getConsultants),
  takeLatest(addConsultantTypes.request, addConsultant),
  takeLatest(updateConsultantTypes.request, updateConsultant),
  takeLatest(deleteConsultantTypes.request, deleteConsultant),
  takeLatest(
    resendConsultantCreationEmailTypes.request,
    resendConsultantAccountCreationEmail,
  ),
  takeLatest(getUsersTypes.request, getUsers),
  takeLatest(getUserSelfTypes.request, getUserSelf),
  takeLatest(addUserTypes.request, addUser),
  takeLatest(updateUserTypes.request, updateUser),
  takeLatest(deleteUserTypes.request, deleteUser),
  takeLatest(
    resendUserCreationEmailTypes.request,
    resendUserAccountCreationEmail,
  ),
];
