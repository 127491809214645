import React, { useState, useEffect, useCallback } from 'react';
import {
  Button, FormGroup, FormControl, ControlLabel, Modal, Alert,
} from 'react-bootstrap';
import { createStructuredSelector } from 'reselect';
import {
  bool, oneOfType, func, object,
} from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

import { connect } from 'react-redux';
import { emailRegex } from '../../../utils/constants';
import {
  selectIsUserLoggedIn,
  selectLoading,
  selectError,
} from '../redux/selectors';
import {
  loginUserActions,
  resetAuthState,
  sendEmailReminderActions,
} from '../redux/actions';

import './login.css';
import LoadingSpinner from '../../../components/LoadingSpinner';
import ErrorBar from '../../../components/ErrorBar/ErrorBar';

function useQueryEmail() {
  const searchParams = new URLSearchParams(useLocation().search);
  return decodeURIComponent(searchParams.get('email') || '');
}

const Login = ({
  isAuthLoading,
  authError,
  isUserLoggedIn,

  resetState,
  loginUser,
  sendEmailRemainder,
}) => {
  const [email, setEmail] = useState(useQueryEmail());
  const [secret, setSecret] = useState('');
  const [forgotEmail, setForgotEmail] = useState('');

  const [showModal, setShowModal] = useState(false);
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [showEmailReminderAlert, setShowEmailReminderAlert] = useState(false);

  const [isSignInFormValid, setIsSignInFormValid] = useState(true);
  const [isForgotEmailValid, setIsForgotEmailValid] = useState(true);

  const history = useHistory();

  useEffect(() => {
    if (isUserLoggedIn) {
      history.push('/consultations');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoggedIn]);

  useEffect(() => {
    setIsSignInFormValid(emailRegex.test(email) && secret.length > 7);
  }, [email, secret]);

  useEffect(() => {
    setIsForgotEmailValid(emailRegex.test(forgotEmail));
  }, [forgotEmail]);

  const hideAlerts = useCallback(() => {
    resetState();
    setShowLoginAlert(false);
    setShowEmailReminderAlert(false);
  }, [resetState, setShowLoginAlert, setShowEmailReminderAlert]);

  const handleLoginFormSubmit = useCallback((event) => {
    event.preventDefault();
    loginUser({ email, secret });
    setShowLoginAlert(true);
  }, [loginUser, email, secret, setShowLoginAlert]);

  const handleSendEmailReminder = useCallback((event) => {
    event.preventDefault();
    sendEmailRemainder(forgotEmail);
    setShowModal(false);
    setShowEmailReminderAlert(true);
  }, [sendEmailRemainder, forgotEmail, setShowModal, setShowEmailReminderAlert]);

  const shouldShowAlert = useCallback(() => {
    if (showEmailReminderAlert) {
      if (isAuthLoading) {
        return (
          <Alert bsStyle="info" onClick={() => hideAlerts()}>
            <div style={{ display: 'flex' }}>
              <LoadingSpinner />
              <strong style={{ margin: '0 14px' }}>Palun oodake kuni email on saadetud!</strong>
            </div>
          </Alert>
        );
      }

      if (!authError) {
        return (
          <Alert bsStyle="success" onClick={() => hideAlerts()}>
            <strong>Email saadetud, palun kontrollige oma e-posti!</strong>
          </Alert>
        );
      }
    }

    if (showLoginAlert) {
      if (isAuthLoading) {
        return (
          <Alert bsStyle="info">
            <strong>Logime teid sisse, palun oodake</strong>
          </Alert>
        );
      }
    }

    return null;
  }, [showEmailReminderAlert, isAuthLoading, showLoginAlert, hideAlerts, authError]);

  return (
    <div className="Login">
      <ErrorBar
        error={authError}
        resetErrorState={resetState}
      />
      {shouldShowAlert()}
      <form onSubmit={handleLoginFormSubmit}>
        <FormGroup controlId="email" bsSize="large">
          <ControlLabel>E-posti aadress</ControlLabel>
          <FormControl
            autoFocus
            type="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            autoComplete="username"
          />
        </FormGroup>
        <FormGroup controlId="secret" bsSize="large">
          <ControlLabel>Parool</ControlLabel>
          <FormControl
            value={secret}
            onChange={(event) => setSecret(event.target.value)}
            type="password"
            autoComplete="current-password"
          />
        </FormGroup>
        <Button
          block
          bsSize="large"
          disabled={!isSignInFormValid}
          type="submit"
        >
          Logi sisse!
        </Button>
      </form>
      <div style={{ display: 'flex', justifyContent: 'center', margin: '18px 0' }}>
        <Button bsStyle="link" onClick={() => setShowModal(true)} style={{ cursor: 'pointer' }}>
          Unustasid parooli?
        </Button>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSendEmailReminder}>
              <FormGroup controlId="forgotEmail" bsSize="large">
                <ControlLabel>E-post</ControlLabel>
                <FormControl
                  autoFocus
                  type="forgotEmail"
                  value={forgotEmail}
                  onChange={(event) => setForgotEmail(event.target.value)}
                />
              </FormGroup>
              <Button
                block
                bsSize="large"
                disabled={!isForgotEmailValid}
                type="submit"
              >
                Saada parool emailile
              </Button>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowModal(false)}>Sulge</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

Login.propTypes = {
  isAuthLoading: bool.isRequired,
  authError: oneOfType([bool, object]).isRequired,
  isUserLoggedIn: bool.isRequired,

  resetState: func.isRequired,
  loginUser: func.isRequired,
  sendEmailRemainder: func.isRequired,
};

export default connect(createStructuredSelector({
  isAuthLoading: selectLoading,
  authError: selectError,
  isUserLoggedIn: selectIsUserLoggedIn,
}), {
  resetState: resetAuthState,
  loginUser: loginUserActions.request,
  sendEmailRemainder: sendEmailReminderActions.request,
})(Login);
