/* eslint-disable no-alert */
import React from 'react';
import { Button, ButtonGroup, Glyphicon } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ToolTip from '../../../../utils/ToolTip';

const DeleteBuildingActionButton = ({ deleteSpecificBuilding, isDetailsEditingDisabled }) => (
  <ButtonGroup bsSize="xsmall" vertical>
    {ToolTip(
      'button-delete-building',
      <Button
        /* eslint-disable-next-line consistent-return */
        onClick={() => {
          if (window.confirm('Kindel et soovite hoonet kustutada?')) {
            return deleteSpecificBuilding();
          }
        }}
        disabled={isDetailsEditingDisabled}
      >
        <Glyphicon glyph="glyphicon glyphicon-trash" />
      </Button>,
    )}
  </ButtonGroup>
);

DeleteBuildingActionButton.propTypes = {
  deleteSpecificBuilding: PropTypes.func.isRequired,
  isDetailsEditingDisabled: PropTypes.bool.isRequired,
};

export default DeleteBuildingActionButton;
