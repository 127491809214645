import React, { useCallback, useMemo, useState } from 'react';
import {
  Modal, Button, Checkbox, FormGroup, Radio,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { selectConsultants } from '../../Users/redux/selectors';
import { addConsultantToConsultationActions } from '../redux/actions';

const ConsultantSelectionModal = ({
  showConsultantPicker,
  handleCloseConsultantSelectionModal,
  selectedConsultationId,
}) => {
  const dispatch = useDispatch();

  const consultants = useSelector(selectConsultants);

  const [sendConfirmation, setSendConfirmation] = useState(true);
  const [selectedConsultantId, setSelectedConsultantId] = useState(null);

  const handleCheckSendConfirmationChange = useCallback((event) => {
    setSendConfirmation(event.target.checked);
  }, [setSendConfirmation]);

  const handleConsultantSelectionChange = useCallback((consultant) => {
    setSelectedConsultantId(consultant.id.toString());

    if (!consultant.email_verified_flag) {
      setSendConfirmation(false);
    }
  }, [setSelectedConsultantId, setSendConfirmation]);

  const isEmailConfirmationSendingEnabled = useMemo(() => {
    if (!selectedConsultantId || !consultants.length) {
      return true;
    }

    const selectedConsultant = consultants.find((c) => c.id.toString() === selectedConsultantId);

    return !!(selectedConsultant && selectedConsultant.email_verified_flag);
  }, [selectedConsultantId, consultants]);

  const handleAddConsultantToConsultation = useCallback(() => {
    dispatch(addConsultantToConsultationActions.request({
      consultationId: selectedConsultationId,
      consultantId: selectedConsultantId,
      sendConfirmation,
    }));
    setSelectedConsultantId(null);
    setSendConfirmation(true);
    handleCloseConsultantSelectionModal();
  }, [
    dispatch,
    selectedConsultationId,
    selectedConsultantId,
    sendConfirmation,
    handleCloseConsultantSelectionModal,
  ]);

  return (
    <Modal show={showConsultantPicker} onHide={handleCloseConsultantSelectionModal}>
      <Modal.Header closeButton>
        <Modal.Title>Vali konsultant</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormGroup>
          {consultants.map((consultant) => {
            let displayValue = consultant.email_verified_flag ? '' : '(email kinnitamata) ';

            displayValue += consultant.name;
            displayValue += consultant.company_name ? (`, ${consultant.company_name}`) : '';
            displayValue += consultant.counties ? (` (${consultant.counties})`) : '';

            return (
              <Radio
                name="radioGroup"
                value={consultant.id}
                key={consultant.id}
                checked={selectedConsultantId === consultant.id.toString()}
                onChange={() => handleConsultantSelectionChange(consultant)}
              >
                {displayValue}
              </Radio>
            );
          })}
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <Checkbox
          name="sendConfirmation"
          onChange={handleCheckSendConfirmationChange}
          checked={sendConfirmation}
          disabled={!isEmailConfirmationSendingEnabled}
        >
          Küsi konsulteerijalt kinnitust
        </Checkbox>
        <Button onClick={handleCloseConsultantSelectionModal}>
          Loobu
        </Button>
        <Button
          onClick={handleAddConsultantToConsultation}
          disabled={!selectedConsultantId}
        >
          Salvesta
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConsultantSelectionModal.propTypes = {
  showConsultantPicker: PropTypes.bool.isRequired,
  handleCloseConsultantSelectionModal: PropTypes.func.isRequired,
  selectedConsultationId: PropTypes.number.isRequired,
};

export default ConsultantSelectionModal;
