import React, { useCallback, useEffect, useState } from 'react';
import {
  ControlLabel,
  FormControl,
  FormGroup,
  Panel,
  Grid,
  Row,
  Col,
  Form, Checkbox,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import DeleteBuildingActionButton from './ActionButtons/DeleteBuildingActionButton';
import { buildingStructures, buildingTypes, safeParseInt } from '../../../utils/constants';
import Details from './Details';
import { nextId, removeIdPrefix } from '../../../utils/id-generator';
import Images from '../../Images/components/Images';
import { getTextareaHeight } from '../../../utils/helpers';

const Building = ({
  building,
  index,
  isDetailsEditingDisabled,

  handleBuildingChange,
  deleteSpecificBuilding,
}) => {
  const [descriptionHeight, setDescriptionHeight] = useState('50px');
  const [descriptionId, setDescriptionId] = useState(nextId('description'));
  const [
    isConstructionYearInterval,
    setIsConstructionYearInterval,
  ] = useState(!!building.construction_end_year);

  useEffect(() => {
    setDescriptionId(nextId('description'));
  }, []);

  useEffect(() => {
    setDescriptionHeight(getTextareaHeight({
      id: descriptionId,
    }));
  }, [descriptionId, building.description]);

  const toggleIsConstructionYearInterval = () => {
    if (isConstructionYearInterval) {
      handleBuildingChange({
        key: index, building: { ...building, construction_end_year: null },
      });
    }
    setIsConstructionYearInterval(!isConstructionYearInterval);
  };

  const handleChange = useCallback(({ target: { value, id } }) => {
    const target = removeIdPrefix(id);
    const buildingChanges = {};

    if (target === 'building_type_id') {
      const buildingTypeId = safeParseInt(value, 0);
      const buildingType = buildingTypes.find((t) => t.id === buildingTypeId);

      buildingChanges[target] = value;
      buildingChanges.building_type_name = buildingType && buildingType.name;
    } else if (target === 'building_structure_id') {
      const buildingStructureId = safeParseInt(value, 0);
      const buildingStructure = buildingStructures.find((t) => t.id === buildingStructureId);

      buildingChanges[target] = value;
      buildingChanges.building_structure_name = buildingStructure && buildingStructure.name;
    } else {
      buildingChanges[target] = value;
    }

    handleBuildingChange({
      key: index, building: { ...building, ...buildingChanges },
    });
  }, [handleBuildingChange, index, building]);

  const getBuildingColor = useCallback(() => (index % 2 === 0 ? 'success' : 'info'), [index]);

  return (
    <div>
      <Panel defaultExpanded bsStyle={getBuildingColor()}>
        <Panel.Heading>
          <Grid fluid>
            <Row>
              <Col md={11}>
                <Panel.Title
                  toggle={!isDetailsEditingDisabled}
                >
                  {`${index + 1}. Hoone`}
                </Panel.Title>
              </Col>
              <Col md={1}>
                { !isDetailsEditingDisabled && (
                <DeleteBuildingActionButton
                  deleteSpecificBuilding={deleteSpecificBuilding}
                  isDetailsEditingDisabled={isDetailsEditingDisabled}
                />
                )}
              </Col>
            </Row>
          </Grid>
        </Panel.Heading>
        <Panel.Body collapsible>
          <Form componentClass="div" inline>
            <FormGroup controlId={nextId('building_type_id')}>
              <ControlLabel style={{ margin: '2px' }}>Hoone tüüp: </ControlLabel>
              <FormControl
                componentClass="select"
                value={building.building_type_id || 0}
                onChange={handleChange}
                disabled={isDetailsEditingDisabled}
                style={{ margin: '2px', marginRight: '6px' }}
              >
                {buildingTypes.map((type) => (
                  <option value={type.id} key={type.id}>{type.name}</option>
                ))}
              </FormControl>
            </FormGroup>
            <FormGroup controlId={nextId('building_structure_id')}>
              <ControlLabel style={{ margin: '2px' }}>Hoone tarind: </ControlLabel>
              <FormControl
                componentClass="select"
                value={building.building_structure_id || 0}
                onChange={handleChange}
                disabled={isDetailsEditingDisabled}
                style={{ margin: '2px', marginRight: '6px' }}
              >
                {buildingStructures.map((type) => (
                  <option value={type.id} key={type.id}>{type.name}</option>
                ))}
              </FormControl>
            </FormGroup>
            <br />
            <FormGroup controlId={nextId('construction_year')}>
              <ControlLabel style={{ margin: '2px' }}>Ehitamise aasta: </ControlLabel>
              <FormControl
                value={building.construction_year || null}
                onChange={handleChange}
                type="number"
                disabled={isDetailsEditingDisabled}
                style={{ margin: '2px', marginRight: '6px' }}
              />
            </FormGroup>
            {
              isConstructionYearInterval && (
                <React.Fragment>
                  <span>{' - '}</span>
                  <FormGroup controlId={nextId('construction_end_year')}>
                    <FormControl
                      value={building.construction_end_year || null}
                      onChange={handleChange}
                      type="number"
                      disabled={isDetailsEditingDisabled}
                      style={{ margin: '2px', marginRight: '6px' }}
                    />
                  </FormGroup>
                </React.Fragment>
              )
            }
            <Checkbox
              name="constructionYearInterval"
              onChange={toggleIsConstructionYearInterval}
              checked={isConstructionYearInterval}
              disabled={isDetailsEditingDisabled}
              style={{ margin: '2px', marginRight: '6px' }}
            >
              {' '}
              Kasuta vahemikku
            </Checkbox>
          </Form>
          <br />
          <FormGroup controlId={nextId('images')}>
            <Images
              title="Hoone pildid"
              handleParentChange={handleBuildingChange}
              parentElement={building}
              parentKey={index}
              images={building.files || []}
              parentElementType="building"
              isDetailsEditingDisabled={isDetailsEditingDisabled}
            />
          </FormGroup>
          <FormGroup controlId={descriptionId}>
            <ControlLabel>Hoone üldkirjeldus: </ControlLabel>
            <FormControl
              value={building.description || ''}
              onChange={handleChange}
              componentClass="textarea"
              disabled={isDetailsEditingDisabled}
              style={{
                minWidth: '100%',
                maxWidth: '100%',
                width: '100%',
                minHeight: '50px',
                height: descriptionHeight,
              }}
            />
          </FormGroup>
          <FormGroup controlId={nextId('details')}>
            <Details
              handleBuildingChange={handleBuildingChange}
              building={building}
              buildingKey={index}
              isDetailsEditingDisabled={isDetailsEditingDisabled}
            />
          </FormGroup>
        </Panel.Body>
      </Panel>
    </div>
  );
};

Building.propTypes = {
  building: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
  handleBuildingChange: PropTypes.func.isRequired,
  deleteSpecificBuilding: PropTypes.func.isRequired,
  isDetailsEditingDisabled: PropTypes.bool.isRequired,
};

export default Building;
