/* eslint-disable react/display-name */
import React, {
  useEffect, useMemo, useReducer, useState, useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button, ButtonToolbar, ToggleButton, ToggleButtonGroup,
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import { useDispatch, useSelector } from 'react-redux';
import {
  statusMap,
  DEFAULT_STATUS_ID_ARRAY,
  ALL_STATUSES,
  CONSULTATIONS_DEFAULT_SORT_OPTIONS,
} from '../../../utils/constants';
import ConsultantSelectionModal from './ConsultantSelectionModal';
import ToolTip from '../../../utils/ToolTip';
import { getColumns, expandRow, customTotal } from './ConsultationsTableHelpers';
import { selectConsultations } from '../redux/selectors';
import { selectIsConsultant } from '../../Auth/redux/selectors';
import { getConsultationsActions } from '../redux/actions';
import { getConsultantsActions } from '../../Users/redux/actions';

const { SearchBar, ClearSearchButton } = Search;
const { ExportCSVButton } = CSVExport;

const Consultations = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const consultations = useSelector(selectConsultations);
  const isConsultant = useSelector(selectIsConsultant);

  const [statuses, setStatuses] = useState(ALL_STATUSES);
  const [showConsultantPicker, setShowConsultantPicker] = useState(false);
  const [selectedConsultationId, setSelectedConsultationId] = useState(null);
  const [selectedStatusIds, setSelectedStatusIds] = useState(DEFAULT_STATUS_ID_ARRAY);

  const [statusFilter, setStatusFilter] = useReducer(
    (_, newStatusFilter) => newStatusFilter,
    () => [],
  );

  const options = useMemo(() => ({
    paginationSize: 5,
    pageStartIndex: 1,
    hideSizePerPage: consultations.length < 25,
    hidePageListOnlyOnePage: true,
    firstPageText: 'Esimene',
    prePageText: 'Tagasi',
    nextPageText: 'Järgmine',
    lastPageText: 'Viimane',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [{
      text: '25', value: 25,
    }, {
      text: '50', value: 50,
    }, {
      text: '100', value: 100,
    }, {
      text: 'Kõik', value: consultations.length,
    }],
  }), [consultations]);

  useEffect(() => {
    if (statusFilter && typeof statusFilter === 'function') {
      statusFilter(statuses);
    }
  }, [statusFilter, statuses]);

  useEffect(() => {
    dispatch(getConsultationsActions.request());
    dispatch(getConsultantsActions.request());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowConsultantSelectionModal = useCallback((consultationId) => {
    setShowConsultantPicker(true);
    setSelectedConsultationId(consultationId);
  }, [setShowConsultantPicker, setSelectedConsultationId]);

  const columns = useMemo(() => getColumns({
    handleShowConsultantSelectionModal,
    setStatusFilter,
  }), [handleShowConsultantSelectionModal, setStatusFilter]);

  const handleCloseConsultantSelectionModal = useCallback(() => {
    setShowConsultantPicker(false);
  }, [setShowConsultantPicker]);

  const selectAllStatuses = useCallback(() => {
    setStatuses(ALL_STATUSES);
    setSelectedStatusIds(DEFAULT_STATUS_ID_ARRAY);
  }, [setStatuses, setSelectedStatusIds]);

  const handleStatusFilterChange = useCallback((statusIds) => {
    setStatuses(statusIds
      .map((id) => statusMap.find((status) => status.id === id))
      .map((s) => s && s.value)
      .flat(1));
    setSelectedStatusIds(statusIds);
  }, [setStatuses, setSelectedStatusIds]);

  return (
    <ToolkitProvider
      keyField="id"
      columns={columns}
      data={consultations}
      search
      exportCSV
    >
      {
        (props) => (
          <div>
            <ButtonToolbar
              style={{
                display: 'inline',
                verticalAlign: 'middle',
                margin: '2px',
                marginInlineEnd: '20px',
                marginInlineStart: '20px',
                float: 'left',
                marginBottom: '5px',
              }}
            >
              <Button
                onClick={selectAllStatuses}
              >
                Kõik
              </Button>
              {ToolTip(
                'button-filter',
                <ToggleButtonGroup
                  type="checkbox"
                  value={selectedStatusIds}
                  defaultValue={DEFAULT_STATUS_ID_ARRAY}
                  onChange={handleStatusFilterChange}
                >
                  {!isConsultant
                            && <ToggleButton value={1}>Soovid</ToggleButton>}
                  <ToggleButton value={2}>Pakkumised</ToggleButton>
                  <ToggleButton value={3}>Töös</ToggleButton>
                  <ToggleButton value={4}>Arhiiv</ToggleButton>
                </ToggleButtonGroup>,
                'right',
              )}
            </ButtonToolbar>
            <div style={{
              verticalAlign: 'middle', margin: '2px', float: 'left', marginBottom: '5px',
            }}
            >
              <SearchBar
                {...props.searchProps}
                placeholder="Otsi nõustamist..."
                style={{ height: 'fit-content' }}
              />
              <ClearSearchButton
                {...props.searchProps}
                text="Puhasta"
                style={{ height: 'fit-content' }}
              />
            </div>
            <Button
              style={{
                display: 'inline', verticalAlign: 'middle', margin: '2px', float: 'right', marginBottom: '5px',
              }}
              onClick={() => history.push('/consultations/new')}
            >
              Lisa uus nõustamine
            </Button>
            <ExportCSVButton
              {...props.csvProps}
              style={{
                display: 'inline', verticalAlign: 'middle', margin: '2px', float: 'right', marginBottom: '5px',
              }}
            >
              Ekspordi CSV faili!
            </ExportCSVButton>
            <BootstrapTable
              {...props.baseProps}
              condensed
              rowStyle={{ wordWrap: 'break-word' }}
              defaultSorted={CONSULTATIONS_DEFAULT_SORT_OPTIONS}
              pagination={paginationFactory(options)}
              filter={filterFactory()}
              noDataIndication="Tabel on tühi!"
              expandRow={expandRow}
            />
            {!isConsultant && selectedConsultationId && (
              <ConsultantSelectionModal
                handleCloseConsultantSelectionModal={handleCloseConsultantSelectionModal}
                selectedConsultationId={selectedConsultationId}
                showConsultantPicker={showConsultantPicker}
              />
            )}
          </div>
        )
      }
    </ToolkitProvider>
  );
};

export default Consultations;
