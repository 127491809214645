import React, { useCallback } from 'react';
import { Button, ButtonGroup, Panel } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Building from './Building';

const Buildings = ({
  buildings = [],
  isDetailsEditingDisabled,
  handleConsultationBuildingsChange,
}) => {
  const handleBuildingChange = useCallback(({ key, building }) => {
    const changedBuildings = [...buildings];

    changedBuildings[key] = building;

    handleConsultationBuildingsChange(changedBuildings);
  }, [buildings, handleConsultationBuildingsChange]);

  const deleteBuilding = useCallback((key) => function deleteSpecificBuilding() {
    handleConsultationBuildingsChange(
      buildings.filter((_, index) => index !== key),
    );
  }, [buildings, handleConsultationBuildingsChange]);

  const addBuilding = useCallback(() => {
    handleConsultationBuildingsChange([...buildings, {}]);
  }, [buildings, handleConsultationBuildingsChange]);

  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>Hooned</Panel.Title>
      </Panel.Heading>
      {buildings.map((building, index) => (
        <Building
          building={building}
          /* eslint-disable-next-line react/no-array-index-key */
          key={index}
          index={index}
          isDetailsEditingDisabled={isDetailsEditingDisabled}
          handleBuildingChange={handleBuildingChange}
          deleteSpecificBuilding={deleteBuilding(index)}
        />
      ))}
      {
            !isDetailsEditingDisabled
            && (
            <ButtonGroup style={{ width: '100%' }}>
              <Button
                type="button"
                style={{ width: '100%' }}
                onClick={addBuilding}
                disabled={isDetailsEditingDisabled}
              >
                Lisa uus hoone
              </Button>
            </ButtonGroup>
            )
        }
    </Panel>
  );
};

Buildings.propTypes = {
  buildings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isDetailsEditingDisabled: PropTypes.bool.isRequired,
  handleConsultationBuildingsChange: PropTypes.func.isRequired,
};

export default Buildings;
