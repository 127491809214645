import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Panel } from 'react-bootstrap';
import Row from 'react-bootstrap/lib/Row';
import ImageUploader from './ImageUploader';
import Image from './Image';

const Images = ({
  images = [],
  isDetailsEditingDisabled,
  title,
  maxLength = 10,
  parentKey,
  handleParentChange,
  parentElement,
  parentElementType,
}) => {
  const addImages = useCallback((imagesArray) => {
    if (!imagesArray || !imagesArray.length) {
      return;
    }

    handleParentChange({
      key: parentKey,
      [parentElementType]: {
        ...parentElement,
        files: [...(parentElement.files || []), ...imagesArray],
      },
    });
  }, [parentElement, parentElementType, handleParentChange, parentKey]);

  const handleImageChange = useCallback(({ key, image }) => {
    const newParentElement = { ...parentElement };

    newParentElement.files = [...(newParentElement.files || [])];
    newParentElement.files[key] = image;

    handleParentChange({
      key: parentKey,
      [parentElementType]: newParentElement,
    });
  }, [parentElement, parentElementType, handleParentChange, parentKey]);

  const deleteImage = useCallback((key) => function deleteSpecificImage() {
    handleParentChange({
      key: parentKey,
      [parentElementType]: {
        ...parentElement,
        files: parentElement.files.filter((file, index) => index !== key),
      },
    });
  }, [parentElement, parentElementType, handleParentChange, parentKey]);

  return (
    <Panel defaultExpanded>
      <Panel.Heading>
        <Panel.Title toggle={!isDetailsEditingDisabled}>
          {title}
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body collapsible>
        <Grid fluid>
          <Row>
            {images.map((image, index) => image && (
            <Image
              image={image}
              key={image.id}
              index={index}
              deleteSpecificImage={deleteImage(index)}
              handleImageChange={handleImageChange}
              isDetailsEditingDisabled={isDetailsEditingDisabled}
            />
            ))}
          </Row>
        </Grid>
        {!isDetailsEditingDisabled && images.length < maxLength
            && (
            <ImageUploader
              addImages={addImages}
              maxAddition={maxLength - images.length}
            />
            )}
      </Panel.Body>
    </Panel>
  );
};

Images.propTypes = {
  title: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  handleParentChange: PropTypes.func.isRequired,
  parentKey: PropTypes.number.isRequired,
  parentElement: PropTypes.shape({}).isRequired,
  parentElementType: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isDetailsEditingDisabled: PropTypes.bool.isRequired,
};

Images.defaultProps = {
  maxLength: 10,
};

export default Images;
