import { createLogger } from 'redux-logger';
import { applyMiddleware, compose, createStore } from 'redux';
import monitor from '@redux-saga/simple-saga-monitor';

import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';
import createRootReducer from './createRootReducer';

const isProd = process.env.NODE_ENV === 'production';

export default function configureStore() {
  const sagaMonitor = isProd ? null : monitor;

  let composeEnhancers = compose;

  if (!isProd && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
  }

  const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
  const middlewares = [sagaMiddleware];

  if (!isProd) {
    middlewares.push(createLogger({
      collapsed: true,
      duration: true,
    }));
  }

  const store = createStore(
    createRootReducer(),
    composeEnhancers(applyMiddleware(...middlewares)),
  );

  sagaMiddleware.run(rootSaga);

  if (!isProd) {
    window.store = store;
  }

  return store;
}
