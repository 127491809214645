import React from 'react';
import { Jumbotron } from 'react-bootstrap';

const PageNotFound = () => (
  <div style={{
    margin: '0 auto',
    marginTop: '150px',
    width: '90%',
    minWidth: '380px',
    height: '90%',
    padding: '20px',
    borderRadius: '3px',
    border: '1px solid #ccc',
  }}
  >
    <Jumbotron style={{ paddingLeft: '40px', paddingRight: '40px', marginBottom: '0px' }}>
      <h1>Konsultatsiooni ei leitud</h1>
      <p>
        Vabandame, kuid otsitav konsultatsioon ei ole hetkel kättesaadav
        <br />
        või sellist ei eksisteeri.
        <br />
        <a href="https://maamaja.eu/">Tagasi Maamaja.eu kodulehele</a>
      </p>
    </Jumbotron>
  </div>
);

export default PageNotFound;
