import React, {
  Fragment, useEffect, useState, useCallback,
} from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Button, ControlLabel, Form, FormControl, FormGroup,
} from 'react-bootstrap';
import { getPublicConsultationActions, resetConsultationErrorState } from '../redux/actions';
import {
  makeSelectGetConsultationByUuid,
  selectConsultationRequestError,
  selectConsultationRequestLoading,
} from '../redux/selectors';
import { getTextareaHeight, useSelectorCreator } from '../../../utils/helpers';
import Buildings from './Buildings';
import LoadingSpinner from '../../../components/LoadingSpinner';
import ErrorBar from '../../../components/ErrorBar/ErrorBar';

const PublicConsultation = () => {
  const { consultationUuid } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPublicConsultationActions.request(consultationUuid));
  }, [dispatch, consultationUuid]);

  const consultation = useSelectorCreator(makeSelectGetConsultationByUuid, consultationUuid);
  const handlePdfPrintClick = () => {
    window.open(consultation.report_pdf_path, '_blank');
  };

  const isLoading = useSelector(selectConsultationRequestLoading);
  const error = useSelector(selectConsultationRequestError);

  const [descriptionHeight, setDescriptionHeight] = useState('50px');

  useEffect(() => {
    setDescriptionHeight(getTextareaHeight({
      id: 'description',
    }));
  }, [consultation.description]);

  const resetErrorState = useCallback(() => {
    dispatch(resetConsultationErrorState());
  }, [dispatch]);

  return error ? (<ErrorBar error={error} resetErrorState={resetErrorState} />) : (
    <div>
      {isLoading || !consultation.id ? (
        <Alert bsStyle="info">
          <div style={{ display: 'flex' }}>
            <LoadingSpinner />
            <strong style={{ margin: '0 14px' }}>Palun oodake kuni andmeid laetakse!</strong>
          </div>
        </Alert>
      ) : (
        <React.Fragment>
          {consultation.report_pdf_path && (
          <Button onClick={handlePdfPrintClick}>
            Salvesta PDFina!
          </Button>
          )}
          <div
            id="consultationDetailView"
            style={{
              margin: '0 auto',
              width: '90%',
              maxWidth: '1250px',
              minWidth: '380px',
              padding: '20px',
              borderRadius: '3px',
              border: '1px solid #ccc',
            }}
          >
            <form>
              <Form componentClass="div" inline>
                <FormGroup
                  controlId="cadastral_id"
                >
                  <ControlLabel style={{ margin: '2px' }}>Katastritunnus: </ControlLabel>
                  <FormControl
                    autoFocus
                    type="text"
                    value={consultation.cadastral_id}
                    disabled
                    style={{ margin: '2px', marginRight: '6px' }}
                  />
                  <FormControl.Feedback />
                </FormGroup>
              </Form>
              <br />
              <FormGroup controlId="description">
                <ControlLabel>Kirjeldus: </ControlLabel>
                <FormControl
                  value={consultation.description || ''}
                  componentClass="textarea"
                  style={{
                    minWidth: '100%',
                    maxWidth: '100%',
                    width: '100%',
                    minHeight: '50px',
                    height: descriptionHeight,
                  }}
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>Asukoht: </ControlLabel>
                <FormControl
                  value={`${
                    consultation.local_address}, ${
                    consultation.institution}, ${
                    consultation.municipality}, ${
                    consultation.county}`}
                  type="text"
                  disabled
                />
              </FormGroup>
              <Fragment>
                <br />
                <Form componentClass="div" inline>
                  <FormGroup>
                    <ControlLabel style={{ margin: '2px' }}>Nõustaja nimi: </ControlLabel>
                    <FormControl
                      value={consultation.consultant_name}
                      type="text"
                      disabled
                      style={{ margin: '2px', marginRight: '6px' }}
                    />
                  </FormGroup>
                  {consultation.consultant_company_name && (
                    <FormGroup>
                      <ControlLabel style={{ margin: '2px' }}>Nõustaja firma nimi: </ControlLabel>
                      <FormControl
                        value={consultation.consultant_company_name}
                        type="text"
                        disabled
                        style={{ margin: '2px', marginRight: '6px' }}
                      />
                    </FormGroup>
                  )}
                </Form>
                <br />
                <Form componentClass="div" inline>
                  {consultation.work_start_time && (
                  <FormGroup>
                    <ControlLabel style={{ margin: '2px' }}>Töö algus: </ControlLabel>
                    <FormControl
                      value={moment.utc(consultation.work_start_time).format('YYYY-MM-DD')}
                      type="text"
                      disabled
                      style={{ margin: '2px', marginRight: '6px' }}
                    />
                  </FormGroup>
                  )}
                  {consultation.work_end_time && (
                  <FormGroup>
                    <ControlLabel style={{ margin: '2px' }}>Töö lõpp: </ControlLabel>
                    <FormControl
                      value={moment.utc(consultation.work_end_time).format('YYYY-MM-DD')}
                      type="text"
                      disabled
                      style={{ margin: '2px', marginRight: '6px' }}
                    />
                  </FormGroup>
                  )}
                </Form>
                <br />
              </Fragment>
              <br />
              <Form componentClass="div" inline>
                <FormGroup
                  controlId="client_email"
                >
                  <ControlLabel style={{ margin: '2px' }}>Kliendi e-post: </ControlLabel>
                  <FormControl
                    value={consultation.client_email}
                    type="email"
                    disabled
                    style={{ margin: '2px', marginRight: '6px' }}
                  />
                  <FormControl.Feedback />
                </FormGroup>
                <FormGroup controlId="client_phone">
                  <ControlLabel style={{ margin: '2px' }}>Kliendi telefon: </ControlLabel>
                  <FormControl
                    value={consultation.client_phone}
                    type="text"
                    disabled
                    style={{ margin: '2px', marginRight: '6px' }}
                  />
                </FormGroup>
              </Form>
              <hr />
              <Buildings
                buildings={consultation.buildings || []}
                handleConsultationBuildingsChange={() => {}}
                isDetailsEditingDisabled
              />
            </form>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default PublicConsultation;
