import {
  call, put, cancelled, select,
} from 'redux-saga/effects';
import { SESSION_TOKEN_HEADER_NAME } from '../constants';
import { selectSessionToken } from '../../modules/Auth/redux/selectors';
import { showRefreshSessionModal } from '../../modules/Auth/redux/actions';

const status = (response) => {
  if ((response.status >= 200 && response.status < 300)) {
    return Promise.resolve(response);
  }

  return Promise.reject(response);
};

// eslint-disable-next-line prefer-promise-reject-errors
const handleError = (error) => Promise.reject({
  error,
});

const toJson = (response) => response.json()
  .then((parsedBody) => Promise.resolve({
    status: response.status,
    url: response.url,
    redirected: false,
    ...parsedBody,
  }));

const customApiHost = process.env.CUSTOM_API_HOST;

async function customFetch(options) {
  return fetch(`${customApiHost || ''}${options.path}`, options)
    .then(toJson)
    .then(status)
    .then((res) => res.data)
    .catch(handleError);
}

export function* api(options, { useSession } = { useSession: false }) {
  const abortController = new AbortController();

  try {
    const headers = {
      Accept: 'application/json',
      'Cache-control': 'no-cache',
      Pragma: 'no-cache',
    };

    if (useSession) {
      headers[SESSION_TOKEN_HEADER_NAME] = yield select(selectSessionToken);
    }

    if (!options || !options.withoutContentType) {
      headers['Content-Type'] = 'application/json';
    }

    const reqOptions = {
      headers,
      method: options.method ? options.method.toUpperCase() : 'GET',
      body: JSON.stringify(options.body),
      path: options.path,
      signal: abortController.signal,
    };

    return yield call(customFetch, reqOptions);
  } catch (err) {
    if (err.error && err.error.statusCode === 3002) {
      yield put(showRefreshSessionModal());
    }
    throw err;
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

export function* sessionApi(options) {
  return yield call(api, options, { useSession: true });
}
