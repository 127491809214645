import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { LOGOUT_USER } from '../redux/actionTypes';

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: LOGOUT_USER,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default Logout;
