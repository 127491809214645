import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ToolTip = (toolTipId, elementToWrap, placement = 'top') => {
  const toolTipMap = {
    'days-since-created': 'Päevi loomisest',
    'button-reject': 'Keeldu nõustamisest',
    'button-delete': 'Kustuta nõustamine',
    'button-delete-detail': 'Kustuta kahjustus',
    'button-delete-image': 'Kustuta pilt',
    'button-delete-building': 'Kustuta hoone',
    'button-filter': 'Filtreeri staatuse järgi',
    'button-addConsultant': 'Lisa konsulteerija nõustamisele',
    'button-removeConsultant': 'Eemalda konsulteerija nõustamiselt',
    'button-approveAction': 'Kinnita viimane tegevus.',
    'button-disproveAction': 'Lükka viimane tegevus tagasi.',
    'button-details': 'Nõustamise detailid/muutmine',
    'button-reset': 'Nõustamise lähtestamine. Eemaldab konsultandi. Kustudab sisestatud andmed.',
    'button-delete-user': 'Kustuta kasutaja/nõustaja.',
    'button-resend-confirmation': 'Saada konto loomise email uuesti.',
  };

  return (
    <ChildrenWithTooltip
      id={toolTipId}
      elementToWrap={elementToWrap}
      tooltip={toolTipMap[toolTipId]}
      placement={placement}
    />
  );
};

function ChildrenWithTooltip({
  id, elementToWrap, tooltip, placement,
}) {
  return (
    <OverlayTrigger
      overlay={<Tooltip id={id}>{tooltip}</Tooltip>}
      placement={placement}
      delayShow={1000}
      delayHide={150}
    >
      {elementToWrap}
    </OverlayTrigger>
  );
}

ChildrenWithTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types,react/require-default-props
  elementToWrap: PropTypes.any,
  tooltip: PropTypes.string.isRequired,
  placement: PropTypes.string,
};

ChildrenWithTooltip.defaultProps = {
  placement: 'top',
};

export default ToolTip;
