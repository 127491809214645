import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonGroup,
  Col,
  FormControl,
  FormGroup,
  Glyphicon,
  Grid,
  Image as BootstrapImage,
  Panel,
  Row,
} from 'react-bootstrap';
import { nextId } from '../../../utils/id-generator';
import ToolTip from '../../../utils/ToolTip';
import { getTextareaHeight } from '../../../utils/helpers';

const Image = ({
  image,
  index,
  isDetailsEditingDisabled,
  deleteSpecificImage,
  handleImageChange,
}) => {
  const [descriptionHeight, setDescriptionHeight] = useState('55px');
  const [descriptionId] = useState(nextId('description'));

  useEffect(() => {
    setDescriptionHeight(getTextareaHeight({
      id: descriptionId,
    }));
  }, [image.description, descriptionId]);

  const handleDescriptionChange = useCallback(({ target: { value } }) => {
    if (value.length > 100) {
      return;
    }

    handleImageChange({
      key: index, image: { ...image, description: value },
    });
  }, [image, index, handleImageChange]);

  const imageUrl = image && (image.public_path || image.file_path || '');

  return (
    <Col lg={4} md={4} sm={6} xs={12}>
      <Panel>
        <Panel.Heading>
          <Grid fluid>
            <Row>
              <Col md={10} sm={10} xs={10}>
                <Panel.Title>
                  {`${index + 1}. Pilt`}
                </Panel.Title>
              </Col>
              <Col md={1} sm={1} xs={1}>
                {!isDetailsEditingDisabled && (
                <DeleteImageActionButton
                  deleteSpecificImage={deleteSpecificImage}
                />
                )}
              </Col>
            </Row>
          </Grid>
        </Panel.Heading>
        <Panel.Body>
          <a href={imageUrl} rel="noopener noreferrer" target="_blank">
            <BootstrapImage
              responsive
              src={imageUrl}
              style={{ maxHeight: '345px' }}
              alt={image.original_name}
            />
          </a>
          <FormGroup controlId={descriptionId}>
            <FormControl
              value={(image && image.description) || ''}
              onChange={handleDescriptionChange}
              componentClass="textarea"
              disabled={isDetailsEditingDisabled}
              style={{
                minWidth: '100%',
                maxWidth: '100%',
                width: '100%',
                minHeight: '55px',
                maxHeight: '250px',
                height: descriptionHeight,
              }}
            />
          </FormGroup>
        </Panel.Body>
      </Panel>
    </Col>
  );
};

Image.propTypes = {
  image: PropTypes.shape({}).isRequired,
  index: PropTypes.number.isRequired,
  isDetailsEditingDisabled: PropTypes.bool.isRequired,
  deleteSpecificImage: PropTypes.func.isRequired,
  handleImageChange: PropTypes.func.isRequired,
};

export default Image;

const DeleteImageActionButton = ({ deleteSpecificImage }) => (
  <ButtonGroup bsSize="xsmall" vertical>
    {ToolTip(
      'button-delete-image',
      <Button
        /* eslint-disable-next-line consistent-return */
        onClick={() => {
          // eslint-disable-next-line no-alert
          if (window.confirm('Kindel et soovite pilti kustutada?')) {
            return deleteSpecificImage();
          }
        }}
      >
        <Glyphicon glyph="glyphicon glyphicon-trash" />
      </Button>,
    )}
  </ButtonGroup>
);

DeleteImageActionButton.propTypes = {
  deleteSpecificImage: PropTypes.func.isRequired,
};
